@import '../App/Vars.scss';




.appBackground {

  background: rgb(75,96,107);
  background: -moz-linear-gradient(25deg, rgba(75,96,107,0) 50%, rgba(75,96,107,0.20) 100%);
  background: -webkit-linear-gradient(25deg, rgba(75,96,107,0) 50%, rgba(75,96,107,0.20) 100%);
  background: linear-gradient(25deg, rgba(75,96,107,0) 50%, rgba(75,96,107,0.20) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4b606b",endColorstr="#4b606b",GradientType=1);

  z-index:-1;
  overflow:hidden;

  height:100vh;
  width:100vw;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;

  .bgImage {
    background: url(/fitplan/imgs/splash.jpg) top center no-repeat;
    width: 100%;
    display: block;
    height: 100%;
    background-size: cover;
  }
  .vidIntro {
    width:120%;
    display:block;
    // object-fit: cover;
    position:absolute;
    top:0;
    left:-10%;

  }

  #icon.icon-gologo {
    position:absolute;
    // background:$white;
    fill:$white;
    width: 75vh;
    height: 90vh;
    opacity:0.30;
    top: -25vh;
    right: 3%;
  }

}
