
@import '../App/Vars.scss';

.videoItem {
    position:relative;
	z-index: 110;
	width: 100%;
	max-width: 640px;
	height: auto;
    max-height: 360px;
    background-color: rgba(255,255,255,0.35);
    video {
      background:#fff;
    }
}
.videoPreloader {
  background:rgba(55,66,74,0.85);
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  width:100%;
  height:100%;
  z-index:100;
  overflow: hidden;
  text-align:center;

	.appLoadingSpinner {
			position:fixed;
			top:50%;
			left:50%;
			width:8em;
			height:6em;
			margin:-2em 0 0 -4em;
			text-align:center;

			.loader, .loader:after {
			  border-radius: 50%;
			  width: 4em;
			  height: 4em;
			}
			.loader {
			  margin: 0 auto 20px auto;
			  font-size: 10px;
			  position: relative;
			  text-indent: -9999em;
			  border-top: 0.3em solid rgba(200, 200, 200, 0.2);
			  border-right: 0.3em solid rgba(200, 200, 200, 0.2);
			  border-bottom: 0.3em solid rgba(200, 200, 200, 0.2);
			  border-left: 0.3em solid #C8C8C8;
			  -ms-transform: translateZ(0);
			  transform: translateZ(0);
			  animation: loadSpinner 1.1s infinite linear;
			}
			.loadingText {
				font-size:90%;
				display:block;
				letter-spacing:0.1em;
				text-transform:uppercase;
				color:$white;
			}
	}


	.appMagicSpinner {
			position:fixed;
			margin:0 auto;

			/*
			top:50%;
			left:50%;
			margin:-5em 0 0 -10em;
			width:20em;
			height:10em;
			*/
			text-align:center;

			position: relative;
	    top: 50%;
	    -webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	    transform: translateY(-50%);

			.circle, .circle:after {
			  border-radius: 50%;
			  width: 7em;
			  height: 7em;
			}
			#icon.icon-gologo,
			#icon.icon-check {
				position:absolute;
				top:1.5em;
				left:50%;
				font-size:150%;
				margin:0 0 0 -23px;
				/*
				-webkit-transform: translateX(-50%);
		    -ms-transform: translateX(-50%);
		    transform: translateX(-50%);
				*/
				// background:$white;
				fill:$white;
			}
			#icon.icon-check {
				opacity:0;
			}
			.circle {
			  margin: 0 auto;
			  position: relative;
			  text-indent: -9999em;
			  border-top: 0.2em solid rgba(200, 200, 200, 0.2);
			  border-right: 0.2em solid rgba(200, 200, 200, 0.2);
			  border-bottom: 0.2em solid rgba(200, 200, 200, 0.2);
			  border-left: 0.2em solid #C8C8C8;
			  -ms-transform: translateZ(0);
			  transform: translateZ(0);
			}
			.loadingText {
				font-size:90%;
				margin:20px 0 0 0;
				display:block;
				letter-spacing:0.1em;
				text-transform:uppercase;
				color:$white;
			}
	}

}
