.refModal {
  position:relative;
    min-height: calc(100vh - 4.4em);
    font-weight:100;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .buttons {
        text-align: center;
        button {
            margin: 5px;
        }
    }
    h3 {
      font-size:200%;
      padding:0 0 0.50em 0;
      text-align: center;
    }
    h4 {
      font-size:100%;
      padding:0 0 1.25em 0;
      text-align: center;
    }
}
