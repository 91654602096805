
$red: #D52B1E;
$teal: #3CB6CE;
$blue: #44697D;
$darkblue: #37424A;
$gray: #616365;
$yellow: #FFCB23;
$green: #4DE491;
$white: #fff;
$lightgray: #999;

$blue-gradient: linear-gradient(to bottom, rgba(68,105,125,0) 0%,rgba(68,105,125,0.85) 100%);
$blue-gradient-full: linear-gradient(to bottom, rgba(68,105,125,0) 0%,rgba(68,105,125,1) 100%);
$darkblue-gradient: linear-gradient(to bottom, rgba(55,66,74,0) 0%,rgba(55,66,74,0.65) 100%);
$darkblue-gradient-100: linear-gradient(to bottom, rgba(55,66,74,0) 0%,rgba(55,66,74,1) 100%);
$darkblue-gradient-35: linear-gradient(to bottom, rgba(68,105,125,0) 0%,rgba(68,105,125,0.35) 100%);
$darkblue-gradient-down: linear-gradient(to bottom, rgba(68,105,125,0.65) 0%,rgba(68,105,125,0) 100%);
$yellow-gradient-down: linear-gradient(to bottom, rgba(255,203,35,0.65) 0%,rgba(255,203,35,0) 100%);
$green-gradient-down: linear-gradient(to bottom, rgba(77,228,145,0.65) 0%,rgba(77,228,145,0) 100%);
$teal-gradient-down: linear-gradient(to bottom, rgba(60,182,206,0.65) 0%,rgba(60,182,206,0) 100%);
$white-top-vertical-gradient: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
$white-bottom-vertical-gradient:linear-gradient(to bottom, rgba(255,255,255,0.85) 0%,rgba(255,255,255,1) 87%,rgba(255,255,255,1) 100%);
$white-bottom-gradient:linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
$yellow-bottom-gradient: linear-gradient(to bottom, rgba(255,203,35,0.25) 0%,rgba(255,255,255,1) 100%);
