@import '../App/Vars.scss';





.appHeader {
  position:fixed;
  height:4.5em;
  width:100%;
  z-index:5;
  display:flex;
  background:rgba(68,105,125,0.20);
  justify-content:space-between;
  align-items: center;
  // mix-blend-mode:multiply;

  nav #icon, #icon.icon-24GO {
    width:1.4em;
    height:1.4em;
  }
  #icon.icon-profile {
    fill:$white;
  }
  #icon.icon-24GO {
    // background:$white;
    fill:$white;
    width:2em;
    height:2em;
  }
  nav {

    padding:0 1.5em;
    #icon {

    }
    button {
      height:100%;
      display:block;
    }
  }
  .leftNav {
    #icon {
      margin:0 1em 0 0;

    }

  }
  .rightNav {
    .logout {
      cursor:pointer;

      display:block;
      display:flex;
      align-items:center;
      color:$white;
      text-decoration:none;
      #icon {
        // background:$white;
        fill:$white;
      }
    }
  }
}

// overrides above
// could put this on a parent div, or control another way
.appHeaderMember,
.appHeaderTrainer {
  background:rgba(68,105,125,0.95);
  #icon.icon-24GO {
    opacity:0.30;
  }
  button {
    #icon {
      // background:$white;
      fill:$white;
    }
  }
}
.appHeaderTrainer {
  background:rgba(213,43,30,0.95);
}
