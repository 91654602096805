@import '../App/Vars.scss';



// need to migrate this to whole app/project
// thinking




// temp testing stuff

.red { background-color:red !important; }
.blue { background-color:blue !important; }
.green { background-color:green !important; }
.yellow { background-color:yellow !important; }
.purple { background-color:purple !important; }
.orange { background-color:orange !important; }
.blue { background-color:blue !important; }
.black { background-color:black !important; }
.gray { background-color:gray !important; }
.brown { background-color:brown !important; }

.test {


}

// .debugLinks { display:none !important; }





// BEGIN global stuff





@font-face {
font-family: "ApexNew-Ultra";
  src: url("https://static.24go.co/files/fonts/apex-new/ApexNew-Ultra.eot");
  src: url("https://static.24go.co/files/fonts/apex-new/ApexNew-Ultra.woff") format("woff"),
  url("https://static.24go.co/files/fonts/apex-new/ApexNew-Ultra.otf") format("opentype");
}




#root {
  // same height as the .gc-Header
  padding-top:4em;
}

.btn-primary {
  color:$white;
  border-radius:100em;
  background:$teal;
  text-transform:uppercase;
  padding:0.75em 1.5em;
  font-size:110%;
  font-weight:bold;
  margin:0 0 0.5em 0;
  cursor:pointer;
  transition:all 0.2s ease-out;
  transform-origin: center;
  text-decoration:none;
}
.btn-primary:hover,
.btn-secondary:hover,
.btn-secondary-white:hover {
  transform:scale(1.03,1.03);
}
.btn-secondary {
  border-radius:100em;
  text-decoration:none;
  color:$white;
  font-weight:bold;
  border:0.25em solid $teal;
  background:rgba(0,0,0,0.20);
  padding:0.6em 1.25em;
  font-size:110%;
  margin:0 0 0.5em 0;
  text-transform:uppercase;
  cursor:pointer;
  transition:all 0.2s ease-out;
  transform-origin: center;
}
.btn-secondary-white {
  border-radius:100em;
  color:$white;
  font-weight:bold;
  border:0.25em solid $white;
  background:rgba(0,0,0,0.20);
  padding:0.6em 1.25em;
  font-size:110%;
  margin:0 0 0.5em 0;
  text-transform:uppercase;
  cursor:pointer;
  transition:all 0.2s ease-out;
  transform-origin: center;
  text-decoration:none;
}

.topCircle {
  position:absolute;
  overflow:hidden;
  width:100%;
  height:250px;
  top:-8em;
  left:0;

  span {

    border-radius: 50%;
    height: 600px;
    transform: scaleX(1.3);
    width: 100%;
    background: $darkblue;
    position: absolute;
    top:0;


    /*
    margin:0 auto;
    position:absolute;
    left:0;
    top:-3em;
    border-radius:1000em;
    background:$darkblue;
    height:6em;
    margin-left:-2.5%;

    transform: scaleX(21);
    transform-origin: 0 0;
    width: 5%;
    */
  }
}


// general grid styles
// as a rule maybe we put anything that will need to change based on screen width in a Grid
// anything that is "gc" can be copy/pasta anywhere and not need CSS moved to a compneent?

.gc {
  // applies to all Grid Containers
}
.gi {
  // applies to all Grid Items
}



// header

.gc-Header {
  height:4em;
  color:$white;
  position:fixed;
  top:0;
  left:0;
  z-index:1;
  background:$blue;
  z-index:50;

  .gi {
    align-items: center;
    justify-content: center;
    display:flex;
  }
  a {
    height:100%;
    display:flex;
    align-items:center;
    padding:0 1.5em;
  }
  .icon {
    height:1.25em;
    width:1.25em;
  }
  .gi-Header-Logo {
    .long-24hf-logo {

      width: 10.4em;
      background: #fff;
      padding: 1.5em 0.7em 0.8em 0.7em;
      transform: skewX(-13deg);
      height:auto;

      img {
        display:block;
        width:100%;
        transform: skewX(13deg);
      }
    }
  }
}

.gc-HeaderAuth {
  .gi-Header-Logo {

  }
  .gi-Header-Profile {
    justify-content: flex-start;
  }
  .gi-Header-Logout {
    justify-content: flex-end;
  }
}

.gc-HeaderUnAuth {
  // for unauth
  background:rgba(202, 210, 215, 0.90);

  .gi-Header-Back {

  }
  .gi-Header-Logo {

  }
}

.gc-HeaderLanding {
  /*
  background: rgb(238,117,48);
  background: -moz-linear-gradient(-45deg,  rgba(238,117,48,1) 0%, rgba(255,203,35,1) 100%);
  background: -webkit-linear-gradient(-45deg,  rgba(238,117,48,1) 0%,rgba(255,203,35,1) 100%);
  background: linear-gradient(135deg,  rgba(238,117,48,1) 0%,rgba(255,203,35,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ee7530', endColorstr='#ffcb23',GradientType=1 );
  */
  background:$darkblue;
  // position:absolute;
  box-shadow:0 -5px 25px rgba(0,0,0,0.60);
  height:3em;

  .gi-Header-Logo {
    justify-content: flex-end;
    height:100%;
    .icon {
      padding-right:0.5em;
    }
  }
  .gi-Header-Title {
    justify-content: flex-start;
    font-size:100%;
    font-weight:bold;
    text-transform: uppercase;
    opacity:0.60;
    // mix-blend-mode: overlay;
    font-style: italic;
    padding-left:1.3em;
    height:100%;
  }

}





// END global stuff

// BEGIN specific components aka grid containers





// hero section on landing

.gc-FitPlanHero {
  background:url('/fitplan/imgs/welcome-1.jpg');
  background-repeat:no-repeat;
  background-position:top center;
  background-size: cover;
  position:relative;
  color:$white;
  padding-bottom:8em;
  margin-top:-1em;

  .gi {
    position:relative;
  }

  .gi-FitPlanHero-Info {
    padding:5em 8% 4em 8%;

    h2 {
      // fitplan text
      font-size:500%;
      letter-spacing:2px;
      font-family: 'ApexNew-Ultra', Arial, sans-serif;
      position:relative;
      z-index:1;
      img {
        width:55%;
        display:block;
        padding:0 0 0.35em 0;
        max-width:5em;
      }
    }
    p {
      font-size:115%;
      padding:0em 5% 1.5em 0;
      line-height:1.4;
      position:relative;
      z-index:1;
    }
    .promoVideo {
      width:100%;
      filter:brightness(1.1);
      background:$darkblue;
      // border:5px solid $white;
      box-shadow:2px 2px 100px #000;
    }
  }

  .gi-FitPlanHero-Gradient {
    position:absolute;
    background:green;
    background: -moz-linear-gradient(45deg, rgba(38,59,70,0.8) 0%, rgba(38,59,70,0.85) 23%, rgba(38,59,70,0.25) 100%);
    background: -webkit-linear-gradient(45deg, rgba(38,59,70,0.8) 0%,rgba(38,59,70,0.85) 23%,rgba(38,59,70,0.25) 100%);
    background: linear-gradient(45deg, rgba(38,59,70,0.8) 0%,rgba(38,59,70,0.85) 23%,rgba(38,59,70,0.25) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc263b46', endColorstr='#40263b46',GradientType=1 );
    height:100%;
    width:100%;
  }

  .gi-FitPlanHero-CTA {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding:10% 0 0 0;

    h2 {
      font-size:275%;
      font-weight:bold;
      padding:0 0 0.5em 0;
    }
    p {
      padding:0 0 0.25em 0;
    }
    button {
      font-size:150%;
    }
    a {
      color:#fff;
      font-weight:bold;
      text-decoration: underline;
    }

  }

}





// fit Plan benefits Section on landing
// includes 5 features (location, goal, etc)

.gc-FitPlanBenefits {
  background:$blue;
  color:$white;
  position:relative;
  padding:0 0 10em 0;
  // clip-path: ellipse(80vw 350px at bottom center);


  .topCircle {
    span {
      background:$blue;
    }
  }

  .gi-FitPlanBenefits-title {
    width:84%;
    padding:0 8%;
    /*
    overflow:hidden;
    position:relative;
    */

    h2 {
      font-weight:bold;
      font-size:300%;
      text-align:center;
      padding:0.25em 0 0.25em 0;
      position:relative;
      margin-top:-1.3em;
    }
    p {
      position:relative;
      text-align:center;
      font-size:120%;
      padding:0 0 2em 0;
      font-weight:200;
    }
  }
  .gi-FitPlanBenefits-feature {
    position:relative;
    padding:0.1em 0 0.25em 0;
    text-align:center;
    display:flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-right:2px solid rgba(255,255,255,0.25);

    .icon {
      font-size:600%;
    }

    h4 {
      font-size:120%;
      padding:0.3em 0 0 0;
    }

  }
  .gi-FitPlanBenefits-feature:last-child {
    border:none;
  }


}







// extra benefits section
// two phone screens, CTA, bullet list

.gc-FitPlanMoreBenefits {
  background:$darkblue;
  padding:3em 0 0 0;
  position:relative;
  margin:1em 0 0 0;

  .topCircle {
    span {
      background:$darkblue;
    }
  }

  .gi-FitPlanMoreBenefits-phones {
    position:relative;
    margin:-6em 0 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;

    img {
      width:85%;
      padding:0 0 0 15%;
      display:block;
      max-width:26em;
    }
  }
  .gi-FitPlanMoreBenefits-info {
    position:relative;
    margin:-1em 0 0 0;
    color:#fff;
    padding:0 3em 2em 3em;
    display:flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      width:80%;
      font-size:270%;
      font-weight:bold;
      padding:0 0 0.5em 0;
    }
    ul {
      padding:1em 0 0 0;
      list-style-type: square;
      font-size:90%;
      li {
        margin:0 0 0.75em 1em;
        font-size:115%;
        // line-height:1.3;
        font-weight:400;
      }
    }
  }
}



// bottom of landing

.gc-TeamSection {
  position:relative;
  padding:4em 0 4em 0;
  background:$teal;
  color:#fff;

  .gi-TeamSectionInfo {
    position:relative;
    text-align:center;
    h2 {
      font-size:270%;
      font-weight:bold;
      padding:0 5% 0.3em 5%;
    }
    p {
      padding:0 0 2em 0;
      font-size:110%;
      width:60%;
      margin:0 auto;
      line-height:1.5;
      a {
        color:#fff;
        font-weight:bold;
        white-space:nowrap;
      }
    }
    .btn-secondary-white {

    }
    .divider {
      width:30%;
      border-bottom:2px solid rgba(255,255,255,0.30);
      margin:0 auto 3em auto;
      padding:0 0 3em 0;
    }
    .team {
      margin:0 auto 0 auto;
      max-width:850px;
      padding:0 2em;
      display:flex;
      align-items: center;
      justify-content: center;
    }
    .person {
      padding:0 1em;
      text-align: left;
      h3 {
        font-size:130%;
        padding:0.5em 0 0 0;
        text-transform:uppercase;
        position:relative;
        margin:-2em 0 0 0;
        top:-2.5em;
        color:$darkblue;
        background:$yellow;
        width:auto;
        display:inline-block;
        padding:0.3em 0.6em;
        transform: skewX(-13deg);
        // border-bottom:5px solid $teal;
      }
      img {
        width:100%;
        display:block;
      }
    }

  }
}


.versionNumber {
  // background:#21292E;
  background:$darkblue;
  color:#fff;
  padding:1em 0;
  text-align:center;
  letter-spacing:0.1em;
  p {
    opacity:0.60;
  }
}





// progress bar

.gc-ProgressBar {
    top:63px;
    position:fixed;
    z-index:50;
    left:0;

  .gi-ProgressBar-line {
    height:0.4em;
    position:relative;
    background:rgba(255,255,255,0.60);

    .line {
      width:33%;
      background:$yellow;
      height:100%;
      display:block;
      position:absolute;
      top:0;
      transition:width 1s ease;
      left:0;
    }
  }
}





// progress screens

.gc-Progress {
  position:relative;

  .gi-Progress-Info {
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color:$darkblue;
    font-weight:100;
    // exact measurement
    min-height: calc(100vh - 4.4em);
    // overflow:scroll;
    // flex-flow:row wrap;

    .progress-Info-Wrapper {
      // padding:2.5em 0;
      text-align: center;
      width:100%;
      .button-skewed {
        margin-top:0.25em;
      }
      .button-skewed:last-child {
        margin-left:0.25em;
      }
    }

    h3 {
      font-size:200%;
      padding:0 0 0.50em 0;
      text-align: center;
    }
    h4 {
      font-size:100%;
      padding:0 0 1.25em 0;
      text-align: center;
    }
    // add variations for :
    // two column inputs
    // one column inputs
    // class list type
    // etc
  }
  .gi-Progress-Spacer{
    // placeholder
  }
}



.gc-ClassType {
  padding-top:1em;

  .gi {
    // flex-grow:1;
    // align-items:stretch;
    // flex-direction:column;
    // height:100%;

  }

  .gi-ClassType-Card {
    padding:1em;
    border:1px solid rgba(0,0,0,0.15);
    border-radius:6px;
    position:relative;
    margin:2px;
    height:100%;
    padding:0 1em;

    h5 {
      font-size:120%;
      border-bottom:1px solid rgba(0,0,0,0.15);
      padding:0 0 0.5em 0;
      margin:0 0 0.6em 0;
      font-weight:400;
    }
    .icon {
      width:3.4em;
      height:3.4em;
      opacity:0.60;
      padding:0.7em 0 0 0;
    }
    p {
      font-size:90%;
      opacity:0.60;
      padding:0 0 0.75em 0;
      line-height:1.4;
    }
    input {
      position:absolute;
      top:0;
      right:0;
      margin:1em;
    }
  }
  .gi-ClassType-Card-Selected {
    border:3px solid rgba(0,0,0,0.40);
    transition:0.5s all ease-out;
    margin:0;

    h5 {
      font-weight:bold;
      transition:0.5s all ease-out;
    }
    p {
      opacity:1;
    }
    .icon {
      opacity:1;
      transition:0.5s all ease-out;
    }
  }
}


.gc-Users {
  padding:2.5em 0;
  .gi-Users-Club {

  }
  .gi-Users-Or {

  }
  .gi-Users-NonClub {

  }
}


// backgrounds

.gc-Background {

  background: rgb(75,96,107);
  background: -moz-linear-gradient(25deg, rgba(75,96,107,0) 50%, rgba(75,96,107,0.20) 100%);
  background: -webkit-linear-gradient(25deg, rgba(75,96,107,0) 50%, rgba(75,96,107,0.20) 100%);
  background: linear-gradient(25deg, rgba(75,96,107,0) 50%, rgba(75,96,107,0.20) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4b606b",endColorstr="#4b606b",GradientType=1);

  z-index:-1;
  overflow:hidden;

  height:100vh;
  width:100vw;
  position:fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;

  /*
  .bgImage {
    background: url(/fitplan/imgs/splash.jpg) top center no-repeat;
    width: 100%;
    display: block;
    height: 100%;
    background-size: cover;
  }
  .vidIntro {
    width:120%;
    display:block;
    // object-fit: cover;
    position:absolute;
    top:0;
    left:-10%;
  }
  */

  .gi-BackgroundGeneric {
    .icon {
      position:absolute;
      // background:$white;
      fill:$white;
      width: 75vh;
      height: 90vh;
      opacity:0.30;
      top: -25vh;
      right: 3%;
    }
  }


}






// non grid related Items




.progressBtn {
  background:$teal;
  display:flex;
  align-items:center;
  position:fixed;
  z-index:1;
  text-decoration:none;
  // based on grid 12/100
  width:8%;
  overflow:hidden;
  max-width:5.5em;
  padding:0.75em 0;
  height:8em;
  min-height:8em;
  display:flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  text-align:center;

  // ie11 fix
  top: 52%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  .icon {
    height:1.8em;
    width:1.8em;
  }
  .label {
    display:none;
    margin:0.4em 0 0 0;
    color:#fff;
    text-transform: uppercase;
    font-weight:800;
    font-size:90%;
  }
}

.progressBtnPrev {
  border-radius:0 5px 5px 0;
  left:0;
  background:none;
}


.progressBtnNext {
  border-radius:5px 0 0 5px;

  right:0;
  .icon {
    transform: scaleX(-1);
  }
  .label {

  }
}













// END components / grid stuff







// BEGIN media queries

// matching Material-UI's grid breakpoints



// GOING up

// xs
@media only screen and (min-width: 0px) {

}

// sm
@media only screen and (min-width: 600px) {

}

// md
@media only screen and (min-width: 900px) {

}

// lg
@media only screen and (min-width: 1280px) {

}

// xl
@media only screen and (min-width: 1920px) {

}



// GOING down

// xs
@media only screen and (max-width: 600px) {


  .gc-FitPlanBenefits {
    padding-bottom:7em;
    margin-bottom:3em;
    .gi-FitPlanBenefits-feature {
      border:none;
      padding-bottom:2em;
    }
    .gi-FitPlanBenefits-feature:nth-child(odd) {
    }
    .gi-FitPlanBenefits-feature:nth-child(even) {
      border-right:2px solid rgba(255,255,255,0.25);
    }
    .gi-FitPlanBenefits-feature:nth-child(-n+3) {
      border-bottom:2px solid rgba(255,255,255,0.25);
    }
    .gi-FitPlanBenefits-feature:nth-child(4),
    .gi-FitPlanBenefits-feature:nth-child(5) {
      padding-top:2em;
      padding-bottom:0;
    }
  } // gc-FitPlanBenefits

  .gc-HeaderLanding {
    .gi-Header-Logo {
      .long-24hf-logo {
        width:100%;
        height:100%;
        padding:0.35em 0 0.25em 5%;
        margin-left:-5%;

        // transform:none;
        img {
          // transform:none;
          width: 80%;
          margin: 0 auto;
          max-width: 9em;
        }
      }
    }
  }

  .gc-FitPlanHero {
    .gi-FitPlanHero-Info {
      h2 {
        img {
          width:75%;
          max-width:none;
          padding:0 0 0.25em 0;
          margin:0 auto;
        }
      }
      p {
        font-size:100%;
        text-align: center;
        padding-right:0;
      }
    }
  }

  .gc-TeamSection {
    padding:3em 0;
    .gi-TeamSectionInfo {
      p {
        width:80%;
      }
      .team {
        flex-wrap:wrap;

        .person {
          width:100%;
          margin-bottom:2em;
        }
      }
    }
  }

  .gc-Progress {
    .gi-Progress-Info {
      // padding-bottom:3.5em;
    }
  }

  .progressBtn {
    flex-direction: row-reverse;
    .label {
      display:block;
    }
    .icon {
      width:1em;
      height:1em;
      margin:0 0 0 0.25em;
    }
  }

  .progressBtnPrev {
    display:none;
  }

  .progressBtnNext {
    bottom:0;
    left:0;
    height:3.5em;
    padding:0;
    min-height:0;
    right:auto;
    width:100%;
    max-width:none;
    top:auto;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    border-radius:0;
    .label {
      margin:0;
      font-size:110%;
    }
  }


}


// sm
@media only screen and (max-width: 900px) {


  .gc-FitPlanHero {
    background-position: 100% -55px;
    transform: scaleX(-1);
    background-size:cover;
    .gi-FitPlanHero-Gradient {
      transform: scaleX(-1);
    }
    .gi-FitPlanHero-CTA {
      padding:0;
      margin-top:-3em;
      position:relative;
      transform: scaleX(-1);
      // align-items:flex-start;
      // padding:0em 0 0 2em;
    }
    .gi-FitPlanHero-Info {
      padding-top:9em;
      padding-bottom:1.5em;
      transform: scaleX(-1);

      h2 {
        font-size:300%;
      }
    }
  }


  .gc-FitPlanBenefits {
    .gi-FitPlanBenefits-title {
      h2 {
        font-size:250%;
      }
    }
  }

  .gc-TeamSection {
    .gi-TeamSectionInfo {
        .person {
          h3 {
            font-size:110%;
            white-space:nowrap;
          }
        }
    }
  }

  .gc-FitPlanMoreBenefits {
    padding:0 0 3em 0;
    margin:0;

    .gi-FitPlanMoreBenefits-info {
      padding:0 2em;
      margin-top:-2em;
      h2 {
        width:90%;
        font-size:220%;
        text-align:center;
        margin:0 auto;
      }
      p {
        text-align: center;
      }
      ul {
        width:90%;
        margin:0 auto;
      }
    }
  }

  .gc-Background {
    .gi-BackgroundGeneric {
      .icon {
        width: 45vh;
        height: 90vh;
        top: -27vh;
        right: -12%;
      }
    }
  }

  .topCircle {
    top:-6.6em;
    span {
      height:240px;
    }
  }



}

// md
@media only screen and (max-width: 1280px) {

}

// lg
@media only screen and (max-width: 1920px) {

}
