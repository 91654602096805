@import '../App/Vars.scss';



.appSplash {
  height: 100%;

  /*
  background: -moz-linear-gradient(top, rgba(55,66,74,0) 0%, rgba(55,66,74,0.9) 100%);
  background: -webkit-linear-gradient(top, rgba(55,66,74,0) 0%,rgba(55,66,74,0.9) 100%);
  background: linear-gradient(to bottom, rgba(55,66,74,0) 0%,rgba(55,66,74,0.9) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0037424a', endColorstr='#e637424a',GradientType=0 );
  */

  .bottom {
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    display:flex;
    background:rgba(55,66,74,0.90);
    background-blend-mode: color;
    color:$white;
    padding:2em 0 2em;
    justify-content: center;
    align-items: center;


    .logo {
      display:block;
      width:10%;
      margin:0 0 0 5%;
      img {
        width:100%;
      }
    }

    .copy {
      // padding:0 0 0 2em;
      width:35%;
      padding:0 0 0 3%;
      h1 {
        font-size:320%;
        font-weight:600;
        margin:0 0 0.2em 0;
        line-height:1.1;
      }
      h2 {
        font-size:130%;
        line-height:1.4;
        font-weight:300;
      }
    }

    .cta {
      padding:0 7% 0 5%;
      width:25%;

      button {
        display:block;
        width:100%;
        padding:1em;
        font-size:200%;
      }
      .button-blue {
        padding:0.8em 1em;
        font-size:120%;
        width:100%;
        margin:0.5em 0 0 0;
        // margin:0.7em auto 0;

      }
    }
  }
}


.appUsers {
  height:92vh;
  position:relative;
  display:flex;
  justify-content:center;
  align-items:flex-start;
  flex-direction:column;
  padding:0 10% 0;

  h1 {
    font-weight:100;
    font-size:280%;
    margin:0 0 0.3em;
    padding:0 10% 0 0;
  }
  h2 {
    font-size:110%;
    font-weight:100;
    opacity:0.75;
    margin:0 0 0.3em;
    padding:0 10% 0 0;
    line-height:1.4;
  }
  h3 {
    font-size:110%;
    line-height:1.2;
    font-weight:300;
  }

  .or {
    display:flex;
    justify-content: center;
    align-items: center;
    p {
      border-left:1px solid rgba(0,0,0,0.20);
      height:100%;
      display:flex;
      align-items:center;
      justify-content: center;
      span {
        font-size:200%;
        font-weight:100;
        color:#bbb;
        display:flex;
        align-items: center;
        position:relative;
        height:2em;
        left:-0.5em;
        background:#fff;
      }
    }
  }

  .subText {
    float:left;
    color:$gray;
    margin:2em 0 0 0;
    .subLink {
      color:$darkblue;
      font-weight:700;
      text-decoration:underline;
      padding:0.75em 0;
      display:block;
      font-size:115%;
      background:transparent;
    }
    .subLinkBottom {
      position:absolute;
      bottom:0;
      right:0;
      padding:2.5em;
      text-decoration: underline;
      background:none;
    }
  }

  .trainerSyncNotice {
    img {
      display:inline-block;
      position:relative;
      top:7px;
      border-radius:100px;
      width:30px;
      height:30px;
      margin:0 0 0 0.2em;
      box-shadow:0 2px 3px rgba(0,0,0,0.70);
    }
  }


  form {
    // do global input styling or a material UI plugin for states?
    // wont touch these for now

    .testshawn {

      display:block;
      padding:0.5em 0;
      width:13em;

      font-size:120%;
      margin:1em 0.5em 1em 0;
      border:none;
      border-bottom:2px solid $darkblue;
      outline:none;
    }
    .inline {
      display:inline-block;
    }

    button {
      margin:1em 0 0 0;
      display:block;
    }
  }

}


.createEmailBirthday{
    float:left;
    padding:15px 0 0 0;
}


.trainerLogin {
  .appUsers {
    h2 {
      font-size:120%;
    }
  }
}

.resetPassword {
  display:block;
  margin:0.25em 0 1em;
}
