@import '../App/Vars.scss';
.modalContentLoadingOverlay {
    height: 90vh;
    width: 100%;
    position: absolute;
}
.changeDayModal {
    //background-color: orange;
    position: absolute;
    display: flex;
    height: 90vh;
    width: 100%;
    flex-direction:row;

    .previewLeft {
        overflow-y: auto;
        padding:2em 3%;
        box-shadow: 3px 0px 0px 0px rgba(0,0,0,0.1);
        height: 100%;

        .inputBlockWrapper {
            padding-top: 0;
            padding-bottom: 1.5em;
            width: 100%;
        }
        .expandTitle {
          border-bottom:2px solid rgba(0,0,0,0.15);
        }
        .optionalSection {
          background:rgba(0,0,0,0.15);
          color:#fff;
          letter-spacing:2px;
          margin:0 0 0.5em 0;
          text-align: center;
          padding:0.2em 2em 0.1em;
          display:inline-block;
          text-transform: uppercase;
          font-size:90%;
        }
        .listItem {
          padding:0.7em 0 0.6em;
          width:105%;
        }
    }
    .mobileFilter {
      animation: slideLeft 400ms;
      animation-fill-mode: both;
      animation-timing-function: ease-in-out;
      height: 100%;
      width: 100%;
      display: flex;
      .previewLeft {
        padding-bottom: 4rem !important;
      }
      .showResultsButton {
        background-color: #3cb6ce;
        color: white;
        font-size: 1.4rem;
        position: fixed;
        left: 0;
        bottom: 0;
        top: auto;
        right: auto;
        height: 3.5rem;
        width: 100%;
      }
    }
    @keyframes slideLeft {
      from {
        opacity: 0;
        transform: translate(-100%);
      }
      to {
        opacity: 1;
        transform: translate(0);
      }
    }
    .resultsRight{
        position:relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        .headerButtonContainer {
          height: 5em;
          padding-left: 1.5em;
          background-color: rgba(97, 99, 101, 0.11);
          button {
            height: 2.5em;
            margin-right: 10px;
            border: solid 2px #3cb6ce;
            border-radius: 3px;
            color: #3cb6ce;
            background-color: transparent;
          }
        }
        //background-color: purple;

        .contentCards {
          display:flex;
          flex-wrap:wrap;
          padding:0 3%;

          .card {
            width:48%;
            margin:0 2% 2% 0;
          }
          .card:nth-child(even) {
            width:50%;
            margin:0 0 2% 0;
          }
          .card-class {
            width:50%;
            margin:0 auto 0.75em auto;
          }
        }



        .searchInput {
          position:absolute;
          top:-0.4em;
          right:3%;
          padding:0 0 0 0;
          display:flex;
          width:25%;
          align-items:center;

          input {
            width:15em;
          }

          #icon.icon-search {
            font-size:75%;
            display:block;
            padding:0 1.5em 0 0;
          }        
        }

        .contentSearch {

        }


        .classResultsBox {
            color: rgba(0, 0, 0, 0.6);
            height:100%;
            overflow-y: auto;
            position: relative;
            display: flex;
            flex-direction:column;
            align-items: center;

            .classCard {
              width: 400px;
              border: 1px solid black;
              padding: 0 10px;
              margin: 10px;
              display: flex;
              flex-direction:row;
              min-height: 120px;
              justify-content:space-between;
              align-items: center;
                font-size:90%;
              @media (max-width: 600px) {
                width: auto;
              }
              .lCard {
              }
              .rCard {
              }
              .classTitle {
                font-size:130%;
                font-weight:800;
                padding: 10px;
              }
              .classData {
                padding-left: 10px;
                padding-bottom:10px;
                .lightLabel {

                  color: rgba(0, 0, 0, 0.4);
                }
              }
              .classCardButton {
      					text-transform:uppercase;
      					text-decoration:none;
      					font-weight:500;
      					height:2.5em;
                background:$teal;
                font-size:110%;
                color:$white;
                padding:0 1.5em 0 1.5em;
                margin-right: 20px;
              }
            }

        }
        .replacementText {
          padding:1em 0 1em 3%;
          margin:0 0 0 0;
          border-bottom:0.3em solid rgba(0,0,0,0.1);
          position:relative;
        }
        .rightHeader {
          padding:2em 0 1.8em 3%;
          margin:0 0 0 0;
          border-bottom:0.1em solid rgba(0,0,0,0.1);
          position:relative;
          @media (max-width: 600px) {
            padding: 0 !important;
          }
        }
        .resultsCount {
          font-size:150%;
        }


        .resultsBox {
          height:80%;
          overflow-y: auto;
          position:relative;
          .spacer {
            padding:1% 0 0 3%;
            position: relative;
            display: flex;
            flex-direction:row;
            justify-content:space-between;
            flex-wrap: wrap;
            align-content:flex-start;
          }

            .updateState {
                display: flex;
                flex-direction:column;
                //nasty IE fixes
                margin-left:100px;
                margin-right:100px;
                justify-content:center;
                align-items:center;
                //background-color:purple;
                width: 100%;
                height: auto;
                text-align: center;
                .lds-dual-ring {
                  display: inline-block;
                  width: 64px;
                  height: 64px;
                  margin: auto;
                }
                .noResultsText {
                  opacity:0.20;
                  text-transform:uppercase;
                  letter-spacing:3px;
                  display:none;
                }
                .noResultsText {
                  opacity:0.20;
                  text-transform:uppercase;
                  letter-spacing:3px;
                  display:none;
                }
                .lds-dual-ring:after {
                  content: " ";
                  display: block;
                  width: 46px;
                  height: 46px;
                  margin: -28px;
                  border-radius: 50%;
                  border: 5px solid #bbb;
                  border-color: #bbb transparent #bbb transparent;
                  animation: lds-dual-ring 1.2s linear infinite;
                }
                @keyframes lds-dual-ring {
                  0% {
                    transform: rotate(0deg);
                  }
                  100% {
                    transform: rotate(360deg);
                  }
                }

            }
        }

        .workoutCard{
            width:48%;
            margin:2% 1% 0% 0;
            height:280px;
            position: relative;
            .selected {
                border:solid 7px rgb(254,201,47);
            }
            .workoutCardButton {
              background:$teal;
              color:$white;
              padding:0.5em 0.9em;
              position:absolute;
              bottom:1.25em;
              right:1.25em;
              display:block;
              z-index:5;
            }
            .workoutCardPreview {
              background:$teal;
              color:$white;
              padding:0.5em 0.9em;
              position:absolute;
              bottom:1.25em;
              right:7.25em;
              display:block;
              z-index:5;
            }
            .workoutImage {
                display:block;
                width:100%;
                height:100%;
                object-fit: cover;
            }
            .workoutBackground {
              background: -moz-linear-gradient(top, rgba(68,105,125,0) 0%, rgba(68,105,125,1) 100%);
              background: -webkit-linear-gradient(top, rgba(68,105,125,0) 0%,rgba(68,105,125,1) 100%);
              background: linear-gradient(to bottom, rgba(68,105,125,0) 0%,rgba(68,105,125,1) 100%);
              filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0044697d', endColorstr='#44697d',GradientType=0 );
                width:100%;
                height:100%;
                top: 0;
                left: 0;
                object-fit: cover;
                position: absolute;
                z-index:1;
            }
            .workoutSelected {
                //outline offset not supported in IE11
                //todo fix with smaller box
                width:100%;
                height:100%;
                top: 0;
                left: 0;
                //background-color: rgba(20,20,20,0.6);
                outline:solid 7px rgb(254,201,47);
                outline-offset: -7px;
                object-fit: cover;
                position: absolute;
                z-index:10;

                .selectedButton {
                  background:$yellow;
                  color:$darkblue;
                  padding:0.5em 0.9em;
                  position:absolute;
                  bottom:0;
                  right:0;
                  display:block;
                  z-index:5;
                }
            }
            .workoutDetails {
                bottom:0;
                z-index:5;
                left: 0;
                display: flex;
                position:absolute;
                flex-direction:column;
                align-items: flex-start;
                justify-content: flex-end;
                padding:0 0 1.25em 1.25em;
                width:75%;

                .workoutTitle {
                    color: white;
                    font-size:140%;
                    width:100%;
                    overflow:hidden;
                    white-space: normal;
                    position:relative;
		                font-weight:600;
                    padding-bottom:0.1em;
                }
                .streamDetails {
                    color: white;
                    font-weight:regular;
                    padding:0.3em 0 0 0;

                    strong {
                      font-weight:100;
                      padding:0 0.2em 0 0;
                    }

                    .intense {
                      height:15px;
                      width: 25px;
                      height: 15px;
                      padding: 0px 5px 0px 5px;

                    }
                }
            }
        }
        .workoutCard:nth-child(even) {
          width:47%;
          margin:2% 3% 0% 0;
        }
        .card-class {
          width:50%;
          margin:0 auto 0.75em auto;
        }
    }
    .changeDayIntensityIcon {
        height: 15px;
        position:relative;
        top:2px;
    }
    .emptySearch {
        height: 20px;
        width: 30px;
    }
    .searchIcon {
        padding-right:5px;
    }
    .hiddenClearButton {
        visibility: hidden;
    }
}
