body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.appSplash {
  height: 100%;
  /*
  background: -moz-linear-gradient(top, rgba(55,66,74,0) 0%, rgba(55,66,74,0.9) 100%);
  background: -webkit-linear-gradient(top, rgba(55,66,74,0) 0%,rgba(55,66,74,0.9) 100%);
  background: linear-gradient(to bottom, rgba(55,66,74,0) 0%,rgba(55,66,74,0.9) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0037424a', endColorstr='#e637424a',GradientType=0 );
  */ }
  .appSplash .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    background: rgba(55, 66, 74, 0.9);
    background-blend-mode: color;
    color: #fff;
    padding: 2em 0 2em;
    justify-content: center;
    align-items: center; }
    .appSplash .bottom .logo {
      display: block;
      width: 10%;
      margin: 0 0 0 5%; }
      .appSplash .bottom .logo img {
        width: 100%; }
    .appSplash .bottom .copy {
      width: 35%;
      padding: 0 0 0 3%; }
      .appSplash .bottom .copy h1 {
        font-size: 320%;
        font-weight: 600;
        margin: 0 0 0.2em 0;
        line-height: 1.1; }
      .appSplash .bottom .copy h2 {
        font-size: 130%;
        line-height: 1.4;
        font-weight: 300; }
    .appSplash .bottom .cta {
      padding: 0 7% 0 5%;
      width: 25%; }
      .appSplash .bottom .cta button {
        display: block;
        width: 100%;
        padding: 1em;
        font-size: 200%; }
      .appSplash .bottom .cta .button-blue {
        padding: 0.8em 1em;
        font-size: 120%;
        width: 100%;
        margin: 0.5em 0 0 0; }

.appUsers {
  height: 92vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 10% 0; }
  .appUsers h1 {
    font-weight: 100;
    font-size: 280%;
    margin: 0 0 0.3em;
    padding: 0 10% 0 0; }
  .appUsers h2 {
    font-size: 110%;
    font-weight: 100;
    opacity: 0.75;
    margin: 0 0 0.3em;
    padding: 0 10% 0 0;
    line-height: 1.4; }
  .appUsers h3 {
    font-size: 110%;
    line-height: 1.2;
    font-weight: 300; }
  .appUsers .or {
    display: flex;
    justify-content: center;
    align-items: center; }
    .appUsers .or p {
      border-left: 1px solid rgba(0, 0, 0, 0.2);
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .appUsers .or p span {
        font-size: 200%;
        font-weight: 100;
        color: #bbb;
        display: flex;
        align-items: center;
        position: relative;
        height: 2em;
        left: -0.5em;
        background: #fff; }
  .appUsers .subText {
    float: left;
    color: #616365;
    margin: 2em 0 0 0; }
    .appUsers .subText .subLink {
      color: #37424A;
      font-weight: 700;
      text-decoration: underline;
      padding: 0.75em 0;
      display: block;
      font-size: 115%;
      background: transparent; }
    .appUsers .subText .subLinkBottom {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 2.5em;
      text-decoration: underline;
      background: none; }
  .appUsers .trainerSyncNotice img {
    display: inline-block;
    position: relative;
    top: 7px;
    border-radius: 100px;
    width: 30px;
    height: 30px;
    margin: 0 0 0 0.2em;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.7); }
  .appUsers form .testshawn {
    display: block;
    padding: 0.5em 0;
    width: 13em;
    font-size: 120%;
    margin: 1em 0.5em 1em 0;
    border: none;
    border-bottom: 2px solid #37424A;
    outline: none; }
  .appUsers form .inline {
    display: inline-block; }
  .appUsers form button {
    margin: 1em 0 0 0;
    display: block; }

.createEmailBirthday {
  float: left;
  padding: 15px 0 0 0; }

.trainerLogin .appUsers h2 {
  font-size: 120%; }

.resetPassword {
  display: block;
  margin: 0.25em 0 1em; }

.red {
  background-color: red !important; }

.blue {
  background-color: blue !important; }

.green {
  background-color: green !important; }

.yellow {
  background-color: yellow !important; }

.purple {
  background-color: purple !important; }

.orange {
  background-color: orange !important; }

.blue {
  background-color: blue !important; }

.black {
  background-color: black !important; }

.gray {
  background-color: gray !important; }

.brown {
  background-color: brown !important; }

@font-face {
  font-family: "ApexNew-Ultra";
  src: url("https://static.24go.co/files/fonts/apex-new/ApexNew-Ultra.eot");
  src: url("https://static.24go.co/files/fonts/apex-new/ApexNew-Ultra.woff") format("woff"), url("https://static.24go.co/files/fonts/apex-new/ApexNew-Ultra.otf") format("opentype"); }

#root {
  padding-top: 4em; }

.btn-primary {
  color: #fff;
  border-radius: 100em;
  background: #3CB6CE;
  text-transform: uppercase;
  padding: 0.75em 1.5em;
  font-size: 110%;
  font-weight: bold;
  margin: 0 0 0.5em 0;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-transform-origin: center;
          transform-origin: center;
  text-decoration: none; }

.btn-primary:hover,
.btn-secondary:hover,
.btn-secondary-white:hover {
  -webkit-transform: scale(1.03, 1.03);
          transform: scale(1.03, 1.03); }

.btn-secondary {
  border-radius: 100em;
  text-decoration: none;
  color: #fff;
  font-weight: bold;
  border: 0.25em solid #3CB6CE;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.6em 1.25em;
  font-size: 110%;
  margin: 0 0 0.5em 0;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-transform-origin: center;
          transform-origin: center; }

.btn-secondary-white {
  border-radius: 100em;
  color: #fff;
  font-weight: bold;
  border: 0.25em solid #fff;
  background: rgba(0, 0, 0, 0.2);
  padding: 0.6em 1.25em;
  font-size: 110%;
  margin: 0 0 0.5em 0;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  -webkit-transform-origin: center;
          transform-origin: center;
  text-decoration: none; }

.topCircle {
  position: absolute;
  overflow: hidden;
  width: 100%;
  height: 250px;
  top: -8em;
  left: 0; }
  .topCircle span {
    border-radius: 50%;
    height: 600px;
    -webkit-transform: scaleX(1.3);
            transform: scaleX(1.3);
    width: 100%;
    background: #37424A;
    position: absolute;
    top: 0;
    /*
    margin:0 auto;
    position:absolute;
    left:0;
    top:-3em;
    border-radius:1000em;
    background:$darkblue;
    height:6em;
    margin-left:-2.5%;

    transform: scaleX(21);
    transform-origin: 0 0;
    width: 5%;
    */ }

.gc-Header {
  height: 4em;
  color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  background: #44697D;
  z-index: 50; }
  .gc-Header .gi {
    align-items: center;
    justify-content: center;
    display: flex; }
  .gc-Header a {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 1.5em; }
  .gc-Header .icon {
    height: 1.25em;
    width: 1.25em; }
  .gc-Header .gi-Header-Logo .long-24hf-logo {
    width: 10.4em;
    background: #fff;
    padding: 1.5em 0.7em 0.8em 0.7em;
    -webkit-transform: skewX(-13deg);
            transform: skewX(-13deg);
    height: auto; }
    .gc-Header .gi-Header-Logo .long-24hf-logo img {
      display: block;
      width: 100%;
      -webkit-transform: skewX(13deg);
              transform: skewX(13deg); }

.gc-HeaderAuth .gi-Header-Profile {
  justify-content: flex-start; }

.gc-HeaderAuth .gi-Header-Logout {
  justify-content: flex-end; }

.gc-HeaderUnAuth {
  background: rgba(202, 210, 215, 0.9); }

.gc-HeaderLanding {
  /*
  background: rgb(238,117,48);
  background: -moz-linear-gradient(-45deg,  rgba(238,117,48,1) 0%, rgba(255,203,35,1) 100%);
  background: -webkit-linear-gradient(-45deg,  rgba(238,117,48,1) 0%,rgba(255,203,35,1) 100%);
  background: linear-gradient(135deg,  rgba(238,117,48,1) 0%,rgba(255,203,35,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ee7530', endColorstr='#ffcb23',GradientType=1 );
  */
  background: #37424A;
  box-shadow: 0 -5px 25px rgba(0, 0, 0, 0.6);
  height: 3em; }
  .gc-HeaderLanding .gi-Header-Logo {
    justify-content: flex-end;
    height: 100%; }
    .gc-HeaderLanding .gi-Header-Logo .icon {
      padding-right: 0.5em; }
  .gc-HeaderLanding .gi-Header-Title {
    justify-content: flex-start;
    font-size: 100%;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.60;
    font-style: italic;
    padding-left: 1.3em;
    height: 100%; }

.gc-FitPlanHero {
  background: url("/fitplan/imgs/welcome-1.jpg");
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  position: relative;
  color: #fff;
  padding-bottom: 8em;
  margin-top: -1em; }
  .gc-FitPlanHero .gi {
    position: relative; }
  .gc-FitPlanHero .gi-FitPlanHero-Info {
    padding: 5em 8% 4em 8%; }
    .gc-FitPlanHero .gi-FitPlanHero-Info h2 {
      font-size: 500%;
      letter-spacing: 2px;
      font-family: 'ApexNew-Ultra', Arial, sans-serif;
      position: relative;
      z-index: 1; }
      .gc-FitPlanHero .gi-FitPlanHero-Info h2 img {
        width: 55%;
        display: block;
        padding: 0 0 0.35em 0;
        max-width: 5em; }
    .gc-FitPlanHero .gi-FitPlanHero-Info p {
      font-size: 115%;
      padding: 0em 5% 1.5em 0;
      line-height: 1.4;
      position: relative;
      z-index: 1; }
    .gc-FitPlanHero .gi-FitPlanHero-Info .promoVideo {
      width: 100%;
      -webkit-filter: brightness(1.1);
              filter: brightness(1.1);
      background: #37424A;
      box-shadow: 2px 2px 100px #000; }
  .gc-FitPlanHero .gi-FitPlanHero-Gradient {
    position: absolute;
    background: green;
    background: -webkit-linear-gradient(45deg, rgba(38, 59, 70, 0.8) 0%, rgba(38, 59, 70, 0.85) 23%, rgba(38, 59, 70, 0.25) 100%);
    background: linear-gradient(45deg, rgba(38, 59, 70, 0.8) 0%, rgba(38, 59, 70, 0.85) 23%, rgba(38, 59, 70, 0.25) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cc263b46', endColorstr='#40263b46',GradientType=1 );
    height: 100%;
    width: 100%; }
  .gc-FitPlanHero .gi-FitPlanHero-CTA {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10% 0 0 0; }
    .gc-FitPlanHero .gi-FitPlanHero-CTA h2 {
      font-size: 275%;
      font-weight: bold;
      padding: 0 0 0.5em 0; }
    .gc-FitPlanHero .gi-FitPlanHero-CTA p {
      padding: 0 0 0.25em 0; }
    .gc-FitPlanHero .gi-FitPlanHero-CTA button {
      font-size: 150%; }
    .gc-FitPlanHero .gi-FitPlanHero-CTA a {
      color: #fff;
      font-weight: bold;
      text-decoration: underline; }

.gc-FitPlanBenefits {
  background: #44697D;
  color: #fff;
  position: relative;
  padding: 0 0 10em 0; }
  .gc-FitPlanBenefits .topCircle span {
    background: #44697D; }
  .gc-FitPlanBenefits .gi-FitPlanBenefits-title {
    width: 84%;
    padding: 0 8%;
    /*
    overflow:hidden;
    position:relative;
    */ }
    .gc-FitPlanBenefits .gi-FitPlanBenefits-title h2 {
      font-weight: bold;
      font-size: 300%;
      text-align: center;
      padding: 0.25em 0 0.25em 0;
      position: relative;
      margin-top: -1.3em; }
    .gc-FitPlanBenefits .gi-FitPlanBenefits-title p {
      position: relative;
      text-align: center;
      font-size: 120%;
      padding: 0 0 2em 0;
      font-weight: 200; }
  .gc-FitPlanBenefits .gi-FitPlanBenefits-feature {
    position: relative;
    padding: 0.1em 0 0.25em 0;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-right: 2px solid rgba(255, 255, 255, 0.25); }
    .gc-FitPlanBenefits .gi-FitPlanBenefits-feature .icon {
      font-size: 600%; }
    .gc-FitPlanBenefits .gi-FitPlanBenefits-feature h4 {
      font-size: 120%;
      padding: 0.3em 0 0 0; }
  .gc-FitPlanBenefits .gi-FitPlanBenefits-feature:last-child {
    border: none; }

.gc-FitPlanMoreBenefits {
  background: #37424A;
  padding: 3em 0 0 0;
  position: relative;
  margin: 1em 0 0 0; }
  .gc-FitPlanMoreBenefits .topCircle span {
    background: #37424A; }
  .gc-FitPlanMoreBenefits .gi-FitPlanMoreBenefits-phones {
    position: relative;
    margin: -6em 0 0 0;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; }
    .gc-FitPlanMoreBenefits .gi-FitPlanMoreBenefits-phones img {
      width: 85%;
      padding: 0 0 0 15%;
      display: block;
      max-width: 26em; }
  .gc-FitPlanMoreBenefits .gi-FitPlanMoreBenefits-info {
    position: relative;
    margin: -1em 0 0 0;
    color: #fff;
    padding: 0 3em 2em 3em;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .gc-FitPlanMoreBenefits .gi-FitPlanMoreBenefits-info h2 {
      width: 80%;
      font-size: 270%;
      font-weight: bold;
      padding: 0 0 0.5em 0; }
    .gc-FitPlanMoreBenefits .gi-FitPlanMoreBenefits-info ul {
      padding: 1em 0 0 0;
      list-style-type: square;
      font-size: 90%; }
      .gc-FitPlanMoreBenefits .gi-FitPlanMoreBenefits-info ul li {
        margin: 0 0 0.75em 1em;
        font-size: 115%;
        font-weight: 400; }

.gc-TeamSection {
  position: relative;
  padding: 4em 0 4em 0;
  background: #3CB6CE;
  color: #fff; }
  .gc-TeamSection .gi-TeamSectionInfo {
    position: relative;
    text-align: center; }
    .gc-TeamSection .gi-TeamSectionInfo h2 {
      font-size: 270%;
      font-weight: bold;
      padding: 0 5% 0.3em 5%; }
    .gc-TeamSection .gi-TeamSectionInfo p {
      padding: 0 0 2em 0;
      font-size: 110%;
      width: 60%;
      margin: 0 auto;
      line-height: 1.5; }
      .gc-TeamSection .gi-TeamSectionInfo p a {
        color: #fff;
        font-weight: bold;
        white-space: nowrap; }
    .gc-TeamSection .gi-TeamSectionInfo .divider {
      width: 30%;
      border-bottom: 2px solid rgba(255, 255, 255, 0.3);
      margin: 0 auto 3em auto;
      padding: 0 0 3em 0; }
    .gc-TeamSection .gi-TeamSectionInfo .team {
      margin: 0 auto 0 auto;
      max-width: 850px;
      padding: 0 2em;
      display: flex;
      align-items: center;
      justify-content: center; }
    .gc-TeamSection .gi-TeamSectionInfo .person {
      padding: 0 1em;
      text-align: left; }
      .gc-TeamSection .gi-TeamSectionInfo .person h3 {
        font-size: 130%;
        padding: 0.5em 0 0 0;
        text-transform: uppercase;
        position: relative;
        margin: -2em 0 0 0;
        top: -2.5em;
        color: #37424A;
        background: #FFCB23;
        width: auto;
        display: inline-block;
        padding: 0.3em 0.6em;
        -webkit-transform: skewX(-13deg);
                transform: skewX(-13deg); }
      .gc-TeamSection .gi-TeamSectionInfo .person img {
        width: 100%;
        display: block; }

.versionNumber {
  background: #37424A;
  color: #fff;
  padding: 1em 0;
  text-align: center;
  letter-spacing: 0.1em; }
  .versionNumber p {
    opacity: 0.60; }

.gc-ProgressBar {
  top: 63px;
  position: fixed;
  z-index: 50;
  left: 0; }
  .gc-ProgressBar .gi-ProgressBar-line {
    height: 0.4em;
    position: relative;
    background: rgba(255, 255, 255, 0.6); }
    .gc-ProgressBar .gi-ProgressBar-line .line {
      width: 33%;
      background: #FFCB23;
      height: 100%;
      display: block;
      position: absolute;
      top: 0;
      -webkit-transition: width 1s ease;
      transition: width 1s ease;
      left: 0; }

.gc-Progress {
  position: relative; }
  .gc-Progress .gi-Progress-Info {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #37424A;
    font-weight: 100;
    min-height: calc(100vh - 4.4em); }
    .gc-Progress .gi-Progress-Info .progress-Info-Wrapper {
      text-align: center;
      width: 100%; }
      .gc-Progress .gi-Progress-Info .progress-Info-Wrapper .button-skewed {
        margin-top: 0.25em; }
      .gc-Progress .gi-Progress-Info .progress-Info-Wrapper .button-skewed:last-child {
        margin-left: 0.25em; }
    .gc-Progress .gi-Progress-Info h3 {
      font-size: 200%;
      padding: 0 0 0.50em 0;
      text-align: center; }
    .gc-Progress .gi-Progress-Info h4 {
      font-size: 100%;
      padding: 0 0 1.25em 0;
      text-align: center; }

.gc-ClassType {
  padding-top: 1em; }
  .gc-ClassType .gi-ClassType-Card {
    padding: 1em;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    position: relative;
    margin: 2px;
    height: 100%;
    padding: 0 1em; }
    .gc-ClassType .gi-ClassType-Card h5 {
      font-size: 120%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding: 0 0 0.5em 0;
      margin: 0 0 0.6em 0;
      font-weight: 400; }
    .gc-ClassType .gi-ClassType-Card .icon {
      width: 3.4em;
      height: 3.4em;
      opacity: 0.60;
      padding: 0.7em 0 0 0; }
    .gc-ClassType .gi-ClassType-Card p {
      font-size: 90%;
      opacity: 0.60;
      padding: 0 0 0.75em 0;
      line-height: 1.4; }
    .gc-ClassType .gi-ClassType-Card input {
      position: absolute;
      top: 0;
      right: 0;
      margin: 1em; }
  .gc-ClassType .gi-ClassType-Card-Selected {
    border: 3px solid rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s all ease-out;
    transition: 0.5s all ease-out;
    margin: 0; }
    .gc-ClassType .gi-ClassType-Card-Selected h5 {
      font-weight: bold;
      -webkit-transition: 0.5s all ease-out;
      transition: 0.5s all ease-out; }
    .gc-ClassType .gi-ClassType-Card-Selected p {
      opacity: 1; }
    .gc-ClassType .gi-ClassType-Card-Selected .icon {
      opacity: 1;
      -webkit-transition: 0.5s all ease-out;
      transition: 0.5s all ease-out; }

.gc-Users {
  padding: 2.5em 0; }

.gc-Background {
  background: #4b606b;
  background: -webkit-linear-gradient(25deg, rgba(75, 96, 107, 0) 50%, rgba(75, 96, 107, 0.2) 100%);
  background: -webkit-linear-gradient(65deg, rgba(75, 96, 107, 0) 50%, rgba(75, 96, 107, 0.2) 100%);
  background: linear-gradient(25deg, rgba(75, 96, 107, 0) 50%, rgba(75, 96, 107, 0.2) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4b606b",endColorstr="#4b606b",GradientType=1);
  z-index: -1;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /*
  .bgImage {
    background: url(/fitplan/imgs/splash.jpg) top center no-repeat;
    width: 100%;
    display: block;
    height: 100%;
    background-size: cover;
  }
  .vidIntro {
    width:120%;
    display:block;
    // object-fit: cover;
    position:absolute;
    top:0;
    left:-10%;
  }
  */ }
  .gc-Background .gi-BackgroundGeneric .icon {
    position: absolute;
    fill: #fff;
    width: 75vh;
    height: 90vh;
    opacity: 0.30;
    top: -25vh;
    right: 3%; }

.progressBtn {
  background: #3CB6CE;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 1;
  text-decoration: none;
  width: 8%;
  overflow: hidden;
  max-width: 5.5em;
  padding: 0.75em 0;
  height: 8em;
  min-height: 8em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  top: 52%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%); }
  .progressBtn .icon {
    height: 1.8em;
    width: 1.8em; }
  .progressBtn .label {
    display: none;
    margin: 0.4em 0 0 0;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 90%; }

.progressBtnPrev {
  border-radius: 0 5px 5px 0;
  left: 0;
  background: none; }

.progressBtnNext {
  border-radius: 5px 0 0 5px;
  right: 0; }
  .progressBtnNext .icon {
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1); }

@media only screen and (max-width: 600px) {
  .gc-FitPlanBenefits {
    padding-bottom: 7em;
    margin-bottom: 3em; }
    .gc-FitPlanBenefits .gi-FitPlanBenefits-feature {
      border: none;
      padding-bottom: 2em; }
    .gc-FitPlanBenefits .gi-FitPlanBenefits-feature:nth-child(even) {
      border-right: 2px solid rgba(255, 255, 255, 0.25); }
    .gc-FitPlanBenefits .gi-FitPlanBenefits-feature:nth-child(-n+3) {
      border-bottom: 2px solid rgba(255, 255, 255, 0.25); }
    .gc-FitPlanBenefits .gi-FitPlanBenefits-feature:nth-child(4),
    .gc-FitPlanBenefits .gi-FitPlanBenefits-feature:nth-child(5) {
      padding-top: 2em;
      padding-bottom: 0; }
  .gc-HeaderLanding .gi-Header-Logo .long-24hf-logo {
    width: 100%;
    height: 100%;
    padding: 0.35em 0 0.25em 5%;
    margin-left: -5%; }
    .gc-HeaderLanding .gi-Header-Logo .long-24hf-logo img {
      width: 80%;
      margin: 0 auto;
      max-width: 9em; }
  .gc-FitPlanHero .gi-FitPlanHero-Info h2 img {
    width: 75%;
    max-width: none;
    padding: 0 0 0.25em 0;
    margin: 0 auto; }
  .gc-FitPlanHero .gi-FitPlanHero-Info p {
    font-size: 100%;
    text-align: center;
    padding-right: 0; }
  .gc-TeamSection {
    padding: 3em 0; }
    .gc-TeamSection .gi-TeamSectionInfo p {
      width: 80%; }
    .gc-TeamSection .gi-TeamSectionInfo .team {
      flex-wrap: wrap; }
      .gc-TeamSection .gi-TeamSectionInfo .team .person {
        width: 100%;
        margin-bottom: 2em; }
  .progressBtn {
    flex-direction: row-reverse; }
    .progressBtn .label {
      display: block; }
    .progressBtn .icon {
      width: 1em;
      height: 1em;
      margin: 0 0 0 0.25em; }
  .progressBtnPrev {
    display: none; }
  .progressBtnNext {
    bottom: 0;
    left: 0;
    height: 3.5em;
    padding: 0;
    min-height: 0;
    right: auto;
    width: 100%;
    max-width: none;
    top: auto;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    border-radius: 0; }
    .progressBtnNext .label {
      margin: 0;
      font-size: 110%; } }

@media only screen and (max-width: 900px) {
  .gc-FitPlanHero {
    background-position: 100% -55px;
    -webkit-transform: scaleX(-1);
            transform: scaleX(-1);
    background-size: cover; }
    .gc-FitPlanHero .gi-FitPlanHero-Gradient {
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
    .gc-FitPlanHero .gi-FitPlanHero-CTA {
      padding: 0;
      margin-top: -3em;
      position: relative;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
    .gc-FitPlanHero .gi-FitPlanHero-Info {
      padding-top: 9em;
      padding-bottom: 1.5em;
      -webkit-transform: scaleX(-1);
              transform: scaleX(-1); }
      .gc-FitPlanHero .gi-FitPlanHero-Info h2 {
        font-size: 300%; }
  .gc-FitPlanBenefits .gi-FitPlanBenefits-title h2 {
    font-size: 250%; }
  .gc-TeamSection .gi-TeamSectionInfo .person h3 {
    font-size: 110%;
    white-space: nowrap; }
  .gc-FitPlanMoreBenefits {
    padding: 0 0 3em 0;
    margin: 0; }
    .gc-FitPlanMoreBenefits .gi-FitPlanMoreBenefits-info {
      padding: 0 2em;
      margin-top: -2em; }
      .gc-FitPlanMoreBenefits .gi-FitPlanMoreBenefits-info h2 {
        width: 90%;
        font-size: 220%;
        text-align: center;
        margin: 0 auto; }
      .gc-FitPlanMoreBenefits .gi-FitPlanMoreBenefits-info p {
        text-align: center; }
      .gc-FitPlanMoreBenefits .gi-FitPlanMoreBenefits-info ul {
        width: 90%;
        margin: 0 auto; }
  .gc-Background .gi-BackgroundGeneric .icon {
    width: 45vh;
    height: 90vh;
    top: -27vh;
    right: -12%; }
  .topCircle {
    top: -6.6em; }
    .topCircle span {
      height: 240px; } }

.modalContentLoadingOverlay {
  height: 90vh;
  width: 100%;
  position: absolute; }

.changeDayModal {
  position: absolute;
  display: flex;
  height: 90vh;
  width: 100%;
  flex-direction: row; }
  .changeDayModal .previewLeft {
    overflow-y: auto;
    padding: 2em 3%;
    box-shadow: 3px 0px 0px 0px rgba(0, 0, 0, 0.1);
    height: 100%; }
    .changeDayModal .previewLeft .inputBlockWrapper {
      padding-top: 0;
      padding-bottom: 1.5em;
      width: 100%; }
    .changeDayModal .previewLeft .expandTitle {
      border-bottom: 2px solid rgba(0, 0, 0, 0.15); }
    .changeDayModal .previewLeft .optionalSection {
      background: rgba(0, 0, 0, 0.15);
      color: #fff;
      letter-spacing: 2px;
      margin: 0 0 0.5em 0;
      text-align: center;
      padding: 0.2em 2em 0.1em;
      display: inline-block;
      text-transform: uppercase;
      font-size: 90%; }
    .changeDayModal .previewLeft .listItem {
      padding: 0.7em 0 0.6em;
      width: 105%; }
  .changeDayModal .mobileFilter {
    -webkit-animation: slideLeft 400ms;
            animation: slideLeft 400ms;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    height: 100%;
    width: 100%;
    display: flex; }
    .changeDayModal .mobileFilter .previewLeft {
      padding-bottom: 4rem !important; }
    .changeDayModal .mobileFilter .showResultsButton {
      background-color: #3cb6ce;
      color: white;
      font-size: 1.4rem;
      position: fixed;
      left: 0;
      bottom: 0;
      top: auto;
      right: auto;
      height: 3.5rem;
      width: 100%; }

@-webkit-keyframes slideLeft {
  from {
    opacity: 0;
    -webkit-transform: translate(-100%);
            transform: translate(-100%); }
  to {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }

@keyframes slideLeft {
  from {
    opacity: 0;
    -webkit-transform: translate(-100%);
            transform: translate(-100%); }
  to {
    opacity: 1;
    -webkit-transform: translate(0);
            transform: translate(0); } }
  .changeDayModal .resultsRight {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column; }
    .changeDayModal .resultsRight .headerButtonContainer {
      height: 5em;
      padding-left: 1.5em;
      background-color: rgba(97, 99, 101, 0.11); }
      .changeDayModal .resultsRight .headerButtonContainer button {
        height: 2.5em;
        margin-right: 10px;
        border: solid 2px #3cb6ce;
        border-radius: 3px;
        color: #3cb6ce;
        background-color: transparent; }
    .changeDayModal .resultsRight .contentCards {
      display: flex;
      flex-wrap: wrap;
      padding: 0 3%; }
      .changeDayModal .resultsRight .contentCards .card {
        width: 48%;
        margin: 0 2% 2% 0; }
      .changeDayModal .resultsRight .contentCards .card:nth-child(even) {
        width: 50%;
        margin: 0 0 2% 0; }
      .changeDayModal .resultsRight .contentCards .card-class {
        width: 50%;
        margin: 0 auto 0.75em auto; }
    .changeDayModal .resultsRight .searchInput {
      position: absolute;
      top: -0.4em;
      right: 3%;
      padding: 0 0 0 0;
      display: flex;
      width: 25%;
      align-items: center; }
      .changeDayModal .resultsRight .searchInput input {
        width: 15em; }
      .changeDayModal .resultsRight .searchInput #icon.icon-search {
        font-size: 75%;
        display: block;
        padding: 0 1.5em 0 0; }
    .changeDayModal .resultsRight .classResultsBox {
      color: rgba(0, 0, 0, 0.6);
      height: 100%;
      overflow-y: auto;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center; }
      .changeDayModal .resultsRight .classResultsBox .classCard {
        width: 400px;
        border: 1px solid black;
        padding: 0 10px;
        margin: 10px;
        display: flex;
        flex-direction: row;
        min-height: 120px;
        justify-content: space-between;
        align-items: center;
        font-size: 90%; }
        @media (max-width: 600px) {
          .changeDayModal .resultsRight .classResultsBox .classCard {
            width: auto; } }
        .changeDayModal .resultsRight .classResultsBox .classCard .classTitle {
          font-size: 130%;
          font-weight: 800;
          padding: 10px; }
        .changeDayModal .resultsRight .classResultsBox .classCard .classData {
          padding-left: 10px;
          padding-bottom: 10px; }
          .changeDayModal .resultsRight .classResultsBox .classCard .classData .lightLabel {
            color: rgba(0, 0, 0, 0.4); }
        .changeDayModal .resultsRight .classResultsBox .classCard .classCardButton {
          text-transform: uppercase;
          text-decoration: none;
          font-weight: 500;
          height: 2.5em;
          background: #3CB6CE;
          font-size: 110%;
          color: #fff;
          padding: 0 1.5em 0 1.5em;
          margin-right: 20px; }
    .changeDayModal .resultsRight .replacementText {
      padding: 1em 0 1em 3%;
      margin: 0 0 0 0;
      border-bottom: 0.3em solid rgba(0, 0, 0, 0.1);
      position: relative; }
    .changeDayModal .resultsRight .rightHeader {
      padding: 2em 0 1.8em 3%;
      margin: 0 0 0 0;
      border-bottom: 0.1em solid rgba(0, 0, 0, 0.1);
      position: relative; }
      @media (max-width: 600px) {
        .changeDayModal .resultsRight .rightHeader {
          padding: 0 !important; } }
    .changeDayModal .resultsRight .resultsCount {
      font-size: 150%; }
    .changeDayModal .resultsRight .resultsBox {
      height: 80%;
      overflow-y: auto;
      position: relative; }
      .changeDayModal .resultsRight .resultsBox .spacer {
        padding: 1% 0 0 3%;
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-content: flex-start; }
      .changeDayModal .resultsRight .resultsBox .updateState {
        display: flex;
        flex-direction: column;
        margin-left: 100px;
        margin-right: 100px;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: auto;
        text-align: center; }
        .changeDayModal .resultsRight .resultsBox .updateState .lds-dual-ring {
          display: inline-block;
          width: 64px;
          height: 64px;
          margin: auto; }
        .changeDayModal .resultsRight .resultsBox .updateState .noResultsText {
          opacity: 0.20;
          text-transform: uppercase;
          letter-spacing: 3px;
          display: none; }
        .changeDayModal .resultsRight .resultsBox .updateState .noResultsText {
          opacity: 0.20;
          text-transform: uppercase;
          letter-spacing: 3px;
          display: none; }
        .changeDayModal .resultsRight .resultsBox .updateState .lds-dual-ring:after {
          content: " ";
          display: block;
          width: 46px;
          height: 46px;
          margin: -28px;
          border-radius: 50%;
          border: 5px solid #bbb;
          border-color: #bbb transparent #bbb transparent;
          -webkit-animation: lds-dual-ring 1.2s linear infinite;
                  animation: lds-dual-ring 1.2s linear infinite; }

@-webkit-keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes lds-dual-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
    .changeDayModal .resultsRight .workoutCard {
      width: 48%;
      margin: 2% 1% 0% 0;
      height: 280px;
      position: relative; }
      .changeDayModal .resultsRight .workoutCard .selected {
        border: solid 7px #fec92f; }
      .changeDayModal .resultsRight .workoutCard .workoutCardButton {
        background: #3CB6CE;
        color: #fff;
        padding: 0.5em 0.9em;
        position: absolute;
        bottom: 1.25em;
        right: 1.25em;
        display: block;
        z-index: 5; }
      .changeDayModal .resultsRight .workoutCard .workoutCardPreview {
        background: #3CB6CE;
        color: #fff;
        padding: 0.5em 0.9em;
        position: absolute;
        bottom: 1.25em;
        right: 7.25em;
        display: block;
        z-index: 5; }
      .changeDayModal .resultsRight .workoutCard .workoutImage {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; }
      .changeDayModal .resultsRight .workoutCard .workoutBackground {
        background: -webkit-linear-gradient(top, rgba(68, 105, 125, 0) 0%, #44697d 100%);
        background: linear-gradient(to bottom, rgba(68, 105, 125, 0) 0%, #44697d 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0044697d', endColorstr='#44697d',GradientType=0 );
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        position: absolute;
        z-index: 1; }
      .changeDayModal .resultsRight .workoutCard .workoutSelected {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        outline: solid 7px #fec92f;
        outline-offset: -7px;
        object-fit: cover;
        position: absolute;
        z-index: 10; }
        .changeDayModal .resultsRight .workoutCard .workoutSelected .selectedButton {
          background: #FFCB23;
          color: #37424A;
          padding: 0.5em 0.9em;
          position: absolute;
          bottom: 0;
          right: 0;
          display: block;
          z-index: 5; }
      .changeDayModal .resultsRight .workoutCard .workoutDetails {
        bottom: 0;
        z-index: 5;
        left: 0;
        display: flex;
        position: absolute;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        padding: 0 0 1.25em 1.25em;
        width: 75%; }
        .changeDayModal .resultsRight .workoutCard .workoutDetails .workoutTitle {
          color: white;
          font-size: 140%;
          width: 100%;
          overflow: hidden;
          white-space: normal;
          position: relative;
          font-weight: 600;
          padding-bottom: 0.1em; }
        .changeDayModal .resultsRight .workoutCard .workoutDetails .streamDetails {
          color: white;
          font-weight: regular;
          padding: 0.3em 0 0 0; }
          .changeDayModal .resultsRight .workoutCard .workoutDetails .streamDetails strong {
            font-weight: 100;
            padding: 0 0.2em 0 0; }
          .changeDayModal .resultsRight .workoutCard .workoutDetails .streamDetails .intense {
            height: 15px;
            width: 25px;
            height: 15px;
            padding: 0px 5px 0px 5px; }
    .changeDayModal .resultsRight .workoutCard:nth-child(even) {
      width: 47%;
      margin: 2% 3% 0% 0; }
    .changeDayModal .resultsRight .card-class {
      width: 50%;
      margin: 0 auto 0.75em auto; }
  .changeDayModal .changeDayIntensityIcon {
    height: 15px;
    position: relative;
    top: 2px; }
  .changeDayModal .emptySearch {
    height: 20px;
    width: 30px; }
  .changeDayModal .searchIcon {
    padding-right: 5px; }
  .changeDayModal .hiddenClearButton {
    visibility: hidden; }

.appUserDashboard .appProfileDetails {
  max-width: 1200px; }

.appUserDashboard .currentPlan {
  width: 100%;
  padding: 1em 0 0 0;
  max-width: 1200px;
  margin: -2em 0 0 0;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  position: relative; }
  .appUserDashboard .currentPlan h2 {
    font-size: 180%;
    font-weight: 700;
    width: 22%;
    padding: 1em 3% 1em 0;
    align-items: center;
    display: flex;
    border-right: 2px solid rgba(0, 0, 0, 0.1); }
  .appUserDashboard .currentPlan .planSummary {
    width: 70%;
    padding: 0 0 0 3%;
    display: flex;
    justify-content: flex-start;
    align-items: center; }
    .appUserDashboard .currentPlan .planSummary ul {
      padding: 1.7em 0;
      line-height: 1.9; }
    .appUserDashboard .currentPlan .planSummary .progressMeters {
      padding: 0 0 0 2em;
      width: 50%; }
      .appUserDashboard .currentPlan .planSummary .progressMeters .progressBar {
        background: #44697D;
        -webkit-transition: width 1s ease-out;
        transition: width 1s ease-out;
        width: 2%;
        display: block;
        position: absolute;
        left: -0.3em;
        top: 0;
        height: 100%;
        -webkit-transform: skew(-9deg, 0deg) scaleX(0.5);
                transform: skew(-9deg, 0deg) scaleX(0.5);
        -webkit-transform-origin: 0 0;
                transform-origin: 0 0;
        padding-left: 1.5em; }
      .appUserDashboard .currentPlan .planSummary .progressMeters .progressCount {
        position: absolute;
        right: 0;
        top: 20%;
        text-transform: uppercase;
        font-size: 130%;
        font-weight: 100; }
      .appUserDashboard .currentPlan .planSummary .progressMeters .progressWorkouts,
      .appUserDashboard .currentPlan .planSummary .progressMeters .progressDays {
        height: 2.5em;
        width: 100%;
        padding: 0;
        margin: 0 0 0.1em 0;
        position: relative;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 1%, rgba(0, 0, 0, 0) 100%);
        background: linear-gradient(to right, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 1%, rgba(0, 0, 0, 0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a000000', endColorstr='#00000000',GradientType=1 ); }
      .appUserDashboard .currentPlan .planSummary .progressMeters .progressWorkouts .progressBar {
        background: #44697D;
        width: 20%; }
      .appUserDashboard .currentPlan .planSummary .progressMeters .progressDays .progressBar {
        background: #37424A;
        width: 100%; }
    .appUserDashboard .currentPlan .planSummary .printBtn {
      background-color: transparent;
      position: absolute;
      right: 0; }
      .appUserDashboard .currentPlan .planSummary .printBtn .icon-print {
        padding: 1em 0 0 0;
        font-size: 120%; }

.appUserDashboard .planCalendarWeek {
  padding: 0 0 2em 0; }

.listingHighlighted {
  background-color: rgba(60, 182, 206, 0.25); }
  .listingHighlighted button {
    background-color: #EA1926 !important; }

/*#region Map Components Styling*/
.mapboxgl-ctrl-top-left .mapboxgl-ctrl {
  border-radius: 0;
  -webkit-filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.5));
          filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.5));
  margin: 2rem 0 0 2rem !important; }

.mapboxgl-ctrl-geocoder {
  font-family: 'Roboto Condensed' !important; }

.mapboxgl-ctrl-geocoder--icon {
  fill: black !important; }

.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right {
  display: none; }

.mapboxgl-ctrl-geocoder--input {
  height: 2.5rem !important; }

.mapboxgl-ctrl-top-left .mapboxgl-ctrl {
  width: calc(100% - 20px) !important;
  margin: 10px 0 0 10px !important; }

.mapboxgl-ctrl-top-left {
  width: 100% !important;
  max-width: 100% !important; }

.mapboxgl-ctrl-geocoder--icon {
  top: 8px !important;
  left: 10px !important; }

.mapboxgl-ctrl-geocoder--icon-close {
  margin-top: 3px !important;
  margin-right: 2px !important; }

/*#endregion Map Components Styling*/

.refModal {
  position: relative;
  min-height: calc(100vh - 4.4em);
  font-weight: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .refModal .buttons {
    text-align: center; }
    .refModal .buttons button {
      margin: 5px; }
  .refModal h3 {
    font-size: 200%;
    padding: 0 0 0.50em 0;
    text-align: center; }
  .refModal h4 {
    font-size: 100%;
    padding: 0 0 1.25em 0;
    text-align: center; }

.group-class {
  padding-top: 1em; }
  .group-class .group-item {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative; }
  .group-class .group-item-card {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    height: 100%;
    margin: 2px;
    padding: 0 1em;
    position: relative; }
    .group-class .group-item-card .card-checkbox {
      color: '#3CB6CE';
      position: absolute !important;
      right: 0;
      top: 0; }
    .group-class .group-item-card h5 {
      font-size: 120%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      padding: 0 0 0.5em 0;
      margin: 0 0 0.6em 0;
      font-weight: 400;
      width: 100%;
      text-align: center; }
    .group-class .group-item-card .icon {
      width: 3.4em;
      height: 3.4em;
      opacity: 0.60;
      padding: 0.7em 0 0 0; }
    .group-class .group-item-card p {
      width: 100%;
      font-size: 90%;
      line-height: 1.4;
      opacity: 0.60;
      padding: 0 0 0.75em 0;
      text-align: center; }
    .group-class .group-item-card input {
      position: absolute;
      top: 0;
      right: 0;
      margin: 1em; }
  .group-class .group-item-card-selected {
    border: 3px solid rgba(0, 0, 0, 0.4);
    -webkit-transition: 0.5s all ease-out;
    transition: 0.5s all ease-out;
    -ms-transition: none;
    margin: 0; }
    .group-class .group-item-card-selected h5 {
      font-weight: bold;
      -webkit-transition: 0.5s font-weight ease-out;
      transition: 0.5s font-weight ease-out;
      -ms-transition: none; }
    .group-class .group-item-card-selected p {
      opacity: 1; }
    .group-class .group-item-card-selected .icon {
      opacity: 1;
      -webkit-transition: 0.5s opacity ease-out;
      transition: 0.5s opacity ease-out;
      -ms-transition: none; }

.listItem {
  margin: 0px;
  padding: 0px; }


.appHeader {
  position: fixed;
  height: 4.5em;
  width: 100%;
  z-index: 5;
  display: flex;
  background: rgba(68, 105, 125, 0.2);
  justify-content: space-between;
  align-items: center; }
  .appHeader nav #icon, .appHeader #icon.icon-24GO {
    width: 1.4em;
    height: 1.4em; }
  .appHeader #icon.icon-profile {
    fill: #fff; }
  .appHeader #icon.icon-24GO {
    fill: #fff;
    width: 2em;
    height: 2em; }
  .appHeader nav {
    padding: 0 1.5em; }
    .appHeader nav button {
      height: 100%;
      display: block; }
  .appHeader .leftNav #icon {
    margin: 0 1em 0 0; }
  .appHeader .rightNav .logout {
    cursor: pointer;
    display: block;
    display: flex;
    align-items: center;
    color: #fff;
    text-decoration: none; }
    .appHeader .rightNav .logout #icon {
      fill: #fff; }

.appHeaderMember,
.appHeaderTrainer {
  background: rgba(68, 105, 125, 0.95); }
  .appHeaderMember #icon.icon-24GO,
  .appHeaderTrainer #icon.icon-24GO {
    opacity: 0.30; }
  .appHeaderMember button #icon,
  .appHeaderTrainer button #icon {
    fill: #fff; }

.appHeaderTrainer {
  background: rgba(213, 43, 30, 0.95); }

.appBackground {
  background: #4b606b;
  background: -webkit-linear-gradient(25deg, rgba(75, 96, 107, 0) 50%, rgba(75, 96, 107, 0.2) 100%);
  background: -webkit-linear-gradient(65deg, rgba(75, 96, 107, 0) 50%, rgba(75, 96, 107, 0.2) 100%);
  background: linear-gradient(25deg, rgba(75, 96, 107, 0) 50%, rgba(75, 96, 107, 0.2) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#4b606b",endColorstr="#4b606b",GradientType=1);
  z-index: -1;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .appBackground .bgImage {
    background: url(/fitplan/imgs/splash.jpg) top center no-repeat;
    width: 100%;
    display: block;
    height: 100%;
    background-size: cover; }
  .appBackground .vidIntro {
    width: 120%;
    display: block;
    position: absolute;
    top: 0;
    left: -10%; }
  .appBackground #icon.icon-gologo {
    position: absolute;
    fill: #fff;
    width: 75vh;
    height: 90vh;
    opacity: 0.30;
    top: -25vh;
    right: 3%; }

.appModal {
  background: rgba(55, 66, 74, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
  text-align: center; }
  .appModal .modalVideoWrapper {
    background-color: #fff;
    margin: 0 auto;
    padding: 0;
    display: inline-block;
    text-align: left;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    .appModal .modalVideoWrapper .closeButton {
      z-index: 9000;
      position: absolute;
      top: -0.2em;
      padding: 0.3em 0.4em;
      -webkit-transform: scaleY(0.75);
              transform: scaleY(0.75);
      font-size: 250%;
      font-weight: 100;
      background: #fff;
      right: 0;
      color: rgba(0, 0, 0, 0.4);
      text-decoration: none;
      border: 1px solid rgba(0, 0, 0, 0.1); }
    .appModal .modalVideoWrapper .modalContent {
      text-align: center; }
      .appModal .modalVideoWrapper .modalContent .modalTitle {
        margin: 0 0 0.3em 0;
        padding: 0; }
      .appModal .modalVideoWrapper .modalContent .modalSubTitle {
        margin: 0 0 1.5em 0;
        font-weight: 100;
        padding: 0; }
      .appModal .modalVideoWrapper .modalContent button {
        margin: 0 0.3em 0 0; }
  .appModal .modalWrapper {
    background-color: #fff;
    margin: 0 auto;
    padding: 4em;
    max-width: 65%;
    max-height: 65%;
    overflow: auto;
    display: inline-block;
    text-align: left;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    .appModal .modalWrapper .closeButton {
      z-index: 9000;
      position: absolute;
      top: -0.2em;
      padding: 0.3em 0.4em;
      -webkit-transform: scaleY(0.75);
              transform: scaleY(0.75);
      font-size: 250%;
      font-weight: 100;
      background: #fff;
      right: 0;
      color: rgba(0, 0, 0, 0.4);
      text-decoration: none;
      border: 1px solid rgba(0, 0, 0, 0.1); }
    .appModal .modalWrapper .modalContent {
      text-align: center; }
      .appModal .modalWrapper .modalContent .modalTitle {
        margin: 0 0 0.3em 0;
        padding: 0; }
      .appModal .modalWrapper .modalContent .modalSubTitle {
        margin: 0 0 1.5em 0;
        font-weight: 100;
        padding: 0; }
      .appModal .modalWrapper .modalContent button {
        margin: 0 0.3em 0 0; }

.videoItem {
  position: relative;
  z-index: 110;
  width: 100%;
  max-width: 640px;
  height: auto;
  max-height: 360px;
  background-color: rgba(255, 255, 255, 0.35); }
  .videoItem video {
    background: #fff; }

.videoPreloader {
  background: rgba(55, 66, 74, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
  text-align: center; }
  .videoPreloader .appLoadingSpinner {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 8em;
    height: 6em;
    margin: -2em 0 0 -4em;
    text-align: center; }
    .videoPreloader .appLoadingSpinner .loader, .videoPreloader .appLoadingSpinner .loader:after {
      border-radius: 50%;
      width: 4em;
      height: 4em; }
    .videoPreloader .appLoadingSpinner .loader {
      margin: 0 auto 20px auto;
      font-size: 10px;
      position: relative;
      text-indent: -9999em;
      border-top: 0.3em solid rgba(200, 200, 200, 0.2);
      border-right: 0.3em solid rgba(200, 200, 200, 0.2);
      border-bottom: 0.3em solid rgba(200, 200, 200, 0.2);
      border-left: 0.3em solid #C8C8C8;
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-animation: loadSpinner 1.1s infinite linear;
              animation: loadSpinner 1.1s infinite linear; }
    .videoPreloader .appLoadingSpinner .loadingText {
      font-size: 90%;
      display: block;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #fff; }
  .videoPreloader .appMagicSpinner {
    position: fixed;
    margin: 0 auto;
    /*
			top:50%;
			left:50%;
			margin:-5em 0 0 -10em;
			width:20em;
			height:10em;
			*/
    text-align: center;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    .videoPreloader .appMagicSpinner .circle, .videoPreloader .appMagicSpinner .circle:after {
      border-radius: 50%;
      width: 7em;
      height: 7em; }
    .videoPreloader .appMagicSpinner #icon.icon-gologo,
    .videoPreloader .appMagicSpinner #icon.icon-check {
      position: absolute;
      top: 1.5em;
      left: 50%;
      font-size: 150%;
      margin: 0 0 0 -23px;
      /*
				-webkit-transform: translateX(-50%);
		    -ms-transform: translateX(-50%);
		    transform: translateX(-50%);
				*/
      fill: #fff; }
    .videoPreloader .appMagicSpinner #icon.icon-check {
      opacity: 0; }
    .videoPreloader .appMagicSpinner .circle {
      margin: 0 auto;
      position: relative;
      text-indent: -9999em;
      border-top: 0.2em solid rgba(200, 200, 200, 0.2);
      border-right: 0.2em solid rgba(200, 200, 200, 0.2);
      border-bottom: 0.2em solid rgba(200, 200, 200, 0.2);
      border-left: 0.2em solid #C8C8C8;
      -webkit-transform: translateZ(0);
              transform: translateZ(0); }
    .videoPreloader .appMagicSpinner .loadingText {
      font-size: 90%;
      margin: 20px 0 0 0;
      display: block;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #fff; }

.icon-play {
  top: 5px;
  fill: #44697D;
  padding-right: 10px;
  color: #44697D; }

.video-link {
  color: #44697D;
  font-weight: 700;
  text-decoration: underline; }

.debugLinks {
  left: 0;
  bottom: 0;
  position: fixed;
  overflow: scroll;
  background: #fff;
  z-index: 1000;
  padding: 0.5em 1em;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1); }
  .debugLinks button {
    text-transform: uppercase;
    background: gray;
    color: white;
    margin: 0.3em;
    padding: 0.1em 0.3em; }

.debugText {
  color: red;
  width: auto; }

html {
  ms-touch-action: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none; }

/* resets */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1.2;
  font-family: 'Roboto Condensed', sans-serif; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

strong {
  font-weight: 700; }

.bold {
  font-weight: bold; }

/* animations */
@-webkit-keyframes zoomInYellow {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  40% {
    opacity: 0.50; }
  80% {
    opacity: 1;
    fill: #FFCB23; }
  100% {
    fill: #37424A; } }
@keyframes zoomInYellow {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  40% {
    opacity: 0.50; }
  80% {
    opacity: 1;
    fill: #FFCB23; }
  100% {
    fill: #37424A; } }

.animate-zoomInYellow {
  -webkit-animation-name: zoomInYellow;
          animation-name: zoomInYellow;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; }

@-webkit-keyframes zoomInGreen {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  40% {
    opacity: 0.50; }
  80% {
    opacity: 1;
    fill: #4DE491; }
  100% {
    fill: #37424A; } }

@keyframes zoomInGreen {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  40% {
    opacity: 0.50; }
  80% {
    opacity: 1;
    fill: #4DE491; }
  100% {
    fill: #37424A; } }

.animate-zoomInGreen {
  -webkit-animation-name: zoomInGreen;
          animation-name: zoomInGreen;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s; }

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
            transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.animate-fadeInDown {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s; }

@-webkit-keyframes slideUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100vh, 0);
            transform: translate3d(0, 100vh, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

@keyframes slideUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100vh, 0);
            transform: translate3d(0, 100vh, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0); } }

.animate-slideUp {
  -webkit-animation-name: slideUp;
          animation-name: slideUp;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }

@-webkit-keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.animate-fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-delay: 0s;
          animation-delay: 0s; }

@-webkit-keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.animate-fadeOut {
  -webkit-animation-name: fadeOut;
          animation-name: fadeOut;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-delay: 1.75s;
          animation-delay: 1.75s; }

@-webkit-keyframes fadeInCheck {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.7, 0.7, 0.7);
            transform: scale3d(0.7, 0.7, 0.7); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

@keyframes fadeInCheck {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.7, 0.7, 0.7);
            transform: scale3d(0.7, 0.7, 0.7); }
  to {
    opacity: 1;
    -webkit-transform: scale3d(1, 1, 1);
            transform: scale3d(1, 1, 1); } }

.animate-fadeInCheck {
  -webkit-animation-name: fadeInCheck;
          animation-name: fadeInCheck;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-delay: 2.5s;
          animation-delay: 2.5s; }

.animate-delay-0-2 {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s; }

.animate-delay-0-5 {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s; }

.animate-delay-0-7 {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s; }

.animate-delay-1-0 {
  -webkit-animation-delay: 1.0s;
          animation-delay: 1.0s; }

.animate-delay-1-2 {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s; }

.animate-delay-1-5 {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s; }

@-webkit-keyframes loadSpinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loadSpinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@-webkit-keyframes circleSpin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg); }
  /*

  0% {
    transform: rotate(0deg);
  }
	25% {
	   transform: rotate(360deg);
	}
	50% {
		transform: rotate(720deg);
	}
	75% {
		transform: rotate(1080deg);
	}
	97% {
		border-top: 0.2em solid rgba(200, 200, 200, 0.2);
		border-right: 0.2em solid rgba(200, 200, 200, 0.2);
		border-bottom: 0.2em solid rgba(200, 200, 200, 0.2);
		border-left: 0.2em solid #C8C8C8;
		transform: rotate(1540deg);
	}
  100% {
		border-color:white;
		// filter: brightness(400%);
  }
  */ }

@keyframes circleSpin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(720deg);
            transform: rotate(720deg); }
  /*

  0% {
    transform: rotate(0deg);
  }
	25% {
	   transform: rotate(360deg);
	}
	50% {
		transform: rotate(720deg);
	}
	75% {
		transform: rotate(1080deg);
	}
	97% {
		border-top: 0.2em solid rgba(200, 200, 200, 0.2);
		border-right: 0.2em solid rgba(200, 200, 200, 0.2);
		border-bottom: 0.2em solid rgba(200, 200, 200, 0.2);
		border-left: 0.2em solid #C8C8C8;
		transform: rotate(1540deg);
	}
  100% {
		border-color:white;
		// filter: brightness(400%);
  }
  */ }

.animate-circle {
  -webkit-animation: circleSpin 3s infinite linear;
          animation: circleSpin 3s infinite linear;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards; }

@-webkit-keyframes iconEffect {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  10% {
    opacity: 1;
    -webkit-transform: scale3d(0.7, 0.7, 0.7);
            transform: scale3d(0.7, 0.7, 0.7); }
  99% {
    opacity: 1;
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
            transform: scale3d(1.2, 1.2, 1.2); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); } }

@keyframes iconEffect {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); }
  10% {
    opacity: 1;
    -webkit-transform: scale3d(0.7, 0.7, 0.7);
            transform: scale3d(0.7, 0.7, 0.7); }
  99% {
    opacity: 1;
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
            transform: scale3d(1.2, 1.2, 1.2); }
  100% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3); } }

.animate-iconEffect {
  -webkit-animation-name: iconEffect;
          animation-name: iconEffect;
  -webkit-animation-duration: 2.5s;
          animation-duration: 2.5s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in; }

/* mixins */
/* font-sizes */
.fontSize110 {
  font-size: 110%; }

.fontSize120 {
  font-size: 120%; }

.fontSize150 {
  font-size: 150%; }

.fontSize200 {
  font-size: 200%; }

.fontSize250 {
  font-size: 250%; }

.fontSize300 {
  font-size: 300%; }

.fontSize350 {
  font-size: 350%; }

/* buttons */
button {
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: 0.3em; }

.button-teal {
  background: #3CB6CE;
  color: #fff;
  font-weight: 700;
  display: inline-block;
  text-decoration: none;
  padding: 0.8em 1.25em;
  font-size: 130%;
  text-align: center; }

.button-blue {
  background: #44697D;
  color: #fff;
  font-weight: 500;
  display: inline-block;
  text-decoration: none;
  padding: 0.8em 1.25em;
  font-size: 130%;
  text-align: center; }

.button-skewed {
  -webkit-transform: skewX(-8deg);
          transform: skewX(-8deg);
  border-radius: 3px; }

.button-mission {
  margin-right: 20px; }

.button-pill {
  border-radius: 300px;
  text-transform: uppercase;
  border: 1px solid #3CB6CE;
  padding: 0.3em 0.7em 0.2em;
  font-size: 85%;
  background: none; }

a {
  color: #44697D; }

/* icons */
#icon, .icon {
  width: 2em;
  height: 2em;
  position: relative;
  display: inline-block;
  fill: #37424A; }

.iconDisabled {
  width: 2em;
  height: 2em;
  position: relative;
  margin: 0 0.4em;
  color: rgba(0, 0, 0, 0.3); }

.icon-check {
  -webkit-mask-size: 160%; }

.icon-arrow-down {
  -webkit-mask-size: 70%; }

.icon-arrow-right {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  -webkit-filter: FlipH;
          filter: FlipH;
  -ms-filter: "FlipH"; }

.icon-calendar {
  -webkit-transform: scale(0.85);
          transform: scale(0.85); }

#icon.icon-close {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

#icon.icon-intensity-low {
  padding: .35em 0;
  height: 1.3em; }

#icon.icon-intensity-medium {
  padding: .35em 0;
  height: 1.3em; }

#icon.icon-intensity-high {
  padding: .35em 0;
  height: 1.3em; }

.inputInlineWrapper {
  display: inline-block;
  margin: 0 0.5em 0 0; }
  .inputInlineWrapper .checkin_code {
    flex-direction: row; }

.inputBlockWrapper {
  display: block;
  margin: 0 0.5em 0 0; }

.inputFormWrapper {
  overflow: hidden;
  clear: both; }
  .inputFormWrapper > .inputInlineWrapper {
    float: left;
    padding: 0.5em 0 1em 0; }
  .inputFormWrapper .button-teal {
    clear: both; }
  .inputFormWrapper .checkin_code {
    margin: 0; }

.fatSep {
  height: 1em;
  width: 100%;
  display: block;
  background: -webkit-linear-gradient(top, rgba(55, 66, 74, 0.1) 0%, rgba(55, 66, 74, 0) 100%);
  background: linear-gradient(to bottom, rgba(55, 66, 74, 0.1) 0%, rgba(55, 66, 74, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a37424a', endColorstr='#0037424a',GradientType=0 ); }

.appLoader {
  background: rgba(55, 66, 74, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
  text-align: center; }
  .appLoader .appLoadingSpinner {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 8em;
    height: 6em;
    margin: -2em 0 0 -4em;
    text-align: center; }
    .appLoader .appLoadingSpinner .loader, .appLoader .appLoadingSpinner .loader:after {
      border-radius: 50%;
      width: 4em;
      height: 4em; }
    .appLoader .appLoadingSpinner .loader {
      margin: 0 auto 20px auto;
      font-size: 10px;
      position: relative;
      text-indent: -9999em;
      border-top: 0.3em solid rgba(200, 200, 200, 0.2);
      border-right: 0.3em solid rgba(200, 200, 200, 0.2);
      border-bottom: 0.3em solid rgba(200, 200, 200, 0.2);
      border-left: 0.3em solid #C8C8C8;
      -webkit-transform: translateZ(0);
              transform: translateZ(0);
      -webkit-animation: loadSpinner 1.1s infinite linear;
              animation: loadSpinner 1.1s infinite linear; }
    .appLoader .appLoadingSpinner .loadingText {
      font-size: 90%;
      display: block;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #fff; }
  .appLoader .appMagicSpinner {
    position: fixed;
    margin: 0 auto;
    /*
			top:50%;
			left:50%;
			margin:-5em 0 0 -10em;
			width:20em;
			height:10em;
			*/
    text-align: center;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    .appLoader .appMagicSpinner .circle, .appLoader .appMagicSpinner .circle:after {
      border-radius: 50%;
      width: 7em;
      height: 7em; }
    .appLoader .appMagicSpinner #icon.icon-gologo,
    .appLoader .appMagicSpinner #icon.icon-check {
      position: absolute;
      top: 1.5em;
      left: 50%;
      font-size: 150%;
      margin: 0 0 0 -23px;
      /*
				-webkit-transform: translateX(-50%);
		    -ms-transform: translateX(-50%);
		    transform: translateX(-50%);
				*/
      fill: #fff; }
    .appLoader .appMagicSpinner #icon.icon-check {
      opacity: 0; }
    .appLoader .appMagicSpinner .circle {
      margin: 0 auto;
      position: relative;
      text-indent: -9999em;
      border-top: 0.2em solid rgba(200, 200, 200, 0.2);
      border-right: 0.2em solid rgba(200, 200, 200, 0.2);
      border-bottom: 0.2em solid rgba(200, 200, 200, 0.2);
      border-left: 0.2em solid #C8C8C8;
      -webkit-transform: translateZ(0);
              transform: translateZ(0); }
    .appLoader .appMagicSpinner .loadingText {
      font-size: 90%;
      margin: 20px 0 0 0;
      display: block;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: #fff; }

.appFullModal {
  background: rgba(55, 66, 74, 0.85);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: hidden;
  text-align: center; }
  .appFullModal .modalHeader {
    height: 10vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .appFullModal .modalHeader #icon.icon-close {
      fill: #fff;
      margin: 0 0 0 0.75em;
      font-size: 75%; }
    .appFullModal .modalHeader .leftSide,
    .appFullModal .modalHeader .rightSide {
      background-color: rgba(0, 0, 0, 0);
      color: #fff;
      padding: 0 3%;
      text-decoration: none; }
    .appFullModal .modalHeader .leftSide {
      font-size: 150%;
      width: 70%;
      text-align: left; }
    .appFullModal .modalHeader .rightSide {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row; }
  .appFullModal .modalWrapper {
    background-color: #fff;
    margin: 0 auto;
    padding: 0;
    width: 100%;
    height: 90vh;
    display: inline-block;
    text-align: left;
    overflow: auto;
    box-shadow: 0 -10px 65px 0 rgba(0, 0, 0, 0.25); }
    .appFullModal .modalWrapper .modalContent {
      text-align: left;
      overflow: hidden; }
  .appFullModal .mobileModalWrapper {
    height: calc(100% - 10vh); }
    .appFullModal .mobileModalWrapper .modalContent {
      height: 100%; }

.appCenterMessage {
  height: 95vh;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  min-height: calc(100vh - 4.4em);
  max-height: calc(100vh - 4.4em); }
  .appCenterMessage .topIcon {
    font-size: 420%; }
  .appCenterMessage h1 {
    font-weight: 100;
    font-size: 250%;
    margin: 0.5em 0 0.3em; }
  .appCenterMessage h2 {
    font-size: 130%;
    font-weight: 100;
    opacity: 0.50;
    margin: 0 0 1.5em; }
  .appCenterMessage .bottomRightBtn {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 2em;
    color: #616365; }
  @media (max-width: 600px), (max-height: 700px) {
    .appCenterMessage .bottomRightBtn {
      position: relative; } }
  .appCenterMessage .planFeatures {
    margin-top: 150px;
    display: flex;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 100em;
    padding: 2em 3.2em 2.5em;
    margin: 0 0 2em 0; }
    .appCenterMessage .planFeatures #icon {
      font-size: 280%;
      margin: 0.1em 0.2em 0.05em 0.2em;
      display: block; }
    .appCenterMessage .planFeatures .planFeaturesTitle {
      position: absolute;
      background: #fff;
      color: rgba(0, 0, 0, 0.3);
      font-size: 80%;
      letter-spacing: 2px;
      top: -0.75em;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 0 -3em;
      height: 1.5em;
      width: 6em;
      left: 50%; }

.whatRecommend {
  font-size: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding: 0 0.75em 0 0.4em;
  margin: 0 0 0 1em;
  background: #FFCB23;
  text-transform: uppercase;
  border-radius: 3px; }
  .whatRecommend #icon {
    width: 0.7em;
    height: 0.7em;
    margin: 0 0.2em; }

.appProgressSteps {
  height: 95vh;
  max-height: calc(100vh - 4.4em);
  min-height: calc(100vh - 4.4em);
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: 0 10% 0;
  text-align: center; }
  .appProgressSteps .progressBar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    background: #fff; }
    .appProgressSteps .progressBar .progress {
      width: 5%;
      height: 100%;
      background: #FFCB23;
      -webkit-transition: 1s width ease-in-out;
      transition: 1s width ease-in-out; }
  .appProgressSteps .previousBtn, .appProgressSteps .nextBtn {
    position: fixed;
    width: 4.5em;
    height: 8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 53%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
    .appProgressSteps .previousBtn .nextText, .appProgressSteps .nextBtn .nextText {
      color: #fff;
      font-size: 95%;
      font-weight: 800;
      display: block;
      padding: 0 0.5em 0 0.5em; }
  .appProgressSteps .previousBtn {
    left: 0;
    border-radius: 0 0.4em 0.4em 0; }
  .appProgressSteps .nextBtn {
    right: 0;
    border-radius: 0.4em 0 0 0.4em;
    background: #3CB6CE;
    padding: 0 0 0 0.3em; }
    .appProgressSteps .nextBtn #icon {
      fill: #fff; }
  .appProgressSteps .nextBtnText #icon {
    width: 1.5em; }
  .appProgressSteps .stepContent {
    position: relative;
    -webkit-transition: 0.5s all ease-in-out;
    transition: 0.5s all ease-in-out;
    /*
    not using this anymore
    left:-100vw;
    left:0;
    */
    width: 100%; }
    .appProgressSteps .stepContent .topIcon {
      font-size: 350%; }
    .appProgressSteps .stepContent h1 {
      font-weight: 100;
      font-size: 200%;
      margin: 0 0 0.5em; }
    .appProgressSteps .stepContent h2 {
      font-weight: 100;
      opacity: 0.50;
      margin: 0 0 1.5em; }
  .appProgressSteps .formDaysSelect {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 1em 0 0 0;
    justify-content: center; }
    .appProgressSteps .formDaysSelect .day {
      padding: 1em 1em;
      margin: 0 0.2em;
      width: auto;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 5px;
      text-decoration: none;
      color: #37424A;
      max-width: 9em;
      background: transparent; }
      .appProgressSteps .formDaysSelect .day .whatRecommend {
        display: none; }
      .appProgressSteps .formDaysSelect .day .inputBlockWrapper {
        display: block;
        overflow: hidden;
        margin: 0.75em 0 0 0;
        padding: 0;
        position: relative; }
      .appProgressSteps .formDaysSelect .day .dayNumber {
        font-size: 300%;
        font-weight: 300; }
      .appProgressSteps .formDaysSelect .day .dayWhen, .appProgressSteps .formDaysSelect .day .dayWhere, .appProgressSteps .formDaysSelect .day .dayWhat {
        border-top: 0;
        margin: 0;
        padding: 1.5em 0 0 0;
        font-size: 80%;
        text-align: left;
        overflow: hidden; }
        .appProgressSteps .formDaysSelect .day .dayWhen select, .appProgressSteps .formDaysSelect .day .dayWhere select, .appProgressSteps .formDaysSelect .day .dayWhat select {
          width: 100%; }
        .appProgressSteps .formDaysSelect .day .dayWhen label, .appProgressSteps .formDaysSelect .day .dayWhere label, .appProgressSteps .formDaysSelect .day .dayWhat label {
          display: block;
          padding: 0 0 0.3em 0; }
    .appProgressSteps .formDaysSelect .daySelected {
      border: 1px solid #37424A; }
      .appProgressSteps .formDaysSelect .daySelected .dayName {
        padding-bottom: 1em;
        border-bottom: 0.1em solid rgba(0, 0, 0, 0.1); }

.planDayModal .modalContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch; }

.planDayModal .editDayFilters {
  width: 24%;
  padding: 2em 3%;
  box-shadow: 3px 0px 0px 0px rgba(0, 0, 0, 0.1); }

.planDayModal .editDayContent {
  width: 70%;
  padding: 2em 0; }
  .planDayModal .editDayContent .contentTitle {
    font-size: 150%;
    padding: 0 0 1em 3%;
    margin: 0 0 1em 0;
    border-bottom: 0.1em solid rgba(0, 0, 0, 0.1); }
  .planDayModal .editDayContent .contentSearch {
    position: absolute;
    top: 0;
    right: 3%;
    padding: 2em 0 0 0;
    display: flex;
    align-items: center; }
    .planDayModal .editDayContent .contentSearch input {
      width: 15em; }
    .planDayModal .editDayContent .contentSearch #icon.icon-search {
      font-size: 75%;
      display: block;
      padding: 0 1.5em 0 0; }
  .planDayModal .editDayContent .contentCards {
    display: flex;
    flex-wrap: wrap;
    padding: 0 3%; }
    .planDayModal .editDayContent .contentCards .card {
      width: 48%;
      margin: 0 2% 2% 0; }
    .planDayModal .editDayContent .contentCards .card:nth-child(even) {
      width: 50%;
      margin: 0 0 2% 0; }
    .planDayModal .editDayContent .contentCards .card-class {
      width: 50%;
      margin: 0 auto 0.75em auto; }

.modalContentLoadingOverlay {
  height: 90vh;
  width: 100%;
  position: absolute; }

.previewDayModal {
  z-index: 110; }

.planPreviewModal {
  overflow-y: scroll !important;
  height: 100%; }
  .planPreviewModal .previewTop {
    display: flex; }
    .planPreviewModal .previewTop .previewLeft {
      width: 70%;
      position: relative; }
      .planPreviewModal .previewTop .previewLeft h1 {
        font-size: 220%;
        text-align: center;
        padding: 1.25em 0 0 0;
        font-weight: 500; }
      .planPreviewModal .previewTop .previewLeft .printBtn {
        background-color: transparent;
        position: absolute;
        right: 0;
        top: 0; }
        .planPreviewModal .previewTop .previewLeft .printBtn .icon-print {
          padding: 1.2em 0.75em 0 0;
          font-size: 100%; }
      .planPreviewModal .previewTop .previewLeft ul {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 1em 0 2.5em 0; }
        .planPreviewModal .previewTop .previewLeft ul li {
          text-align: center;
          padding: 0 1em;
          max-width: 20%; }
          .planPreviewModal .previewTop .previewLeft ul li #icon {
            display: block;
            margin: 0 auto;
            font-size: 210%; }
          .planPreviewModal .previewTop .previewLeft ul li .publisher {
            width: auto;
            height: 3.9em; }
          .planPreviewModal .previewTop .previewLeft ul li #icon.icon-intensity-medium,
          .planPreviewModal .previewTop .previewLeft ul li #icon.icon-intensity-low,
          .planPreviewModal .previewTop .previewLeft ul li #icon.icon-intensity-high {
            -webkit-mask-size: 70%; }
          .planPreviewModal .previewTop .previewLeft ul li .value {
            display: block;
            margin: 0 auto;
            font-weight: 500;
            font-size: 130%;
            padding: 0.25em 0 0.1em 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden; }
          .planPreviewModal .previewTop .previewLeft ul li .label {
            display: block;
            margin: 0 auto;
            text-transform: uppercase;
            font-weight: 100;
            font-size: 90%;
            letter-spacing: 1px; }
    .planPreviewModal .previewTop .previewRight {
      width: 30%;
      max-height: 18em; }
      .planPreviewModal .previewTop .previewRight img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .planPreviewModal .previewTopExtra {
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    padding: 0 3em;
    margin: 0 auto; }
    .planPreviewModal .previewTopExtra ul {
      max-width: 1200px;
      margin: 0 auto; }
      .planPreviewModal .previewTopExtra ul li {
        padding: 1em 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        clear: both;
        overflow: hidden;
        line-height: 1.5; }
        .planPreviewModal .previewTopExtra ul li p {
          float: left;
          padding: 0 2em 0 0; }
      .planPreviewModal .previewTopExtra ul li:last-child {
        border-bottom: none; }
  .planPreviewModal .appTableData {
    padding: 0 3em;
    width: auto; }

.appOverflowContent {
  height: 100%;
  width: auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 3em 0; }
  .appOverflowContent .fatSepFull {
    margin: 0 0 0 -3em;
    padding: 0 9em 0 0; }

.card {
  width: auto;
  padding: 0;
  position: relative;
  display: block;
  overflow: visible;
  background: #44697D;
  min-height: 9em; }
  .card .poster {
    display: block;
    width: 100%;
    padding: 0 0 1em 0;
    background: #44697D;
    -webkit-transition: opacity 0.40s ease-out;
    transition: opacity 0.40s ease-out;
    object-fit: cover; }
  .card .gradient {
    background: -webkit-linear-gradient(top, rgba(68, 105, 125, 0) 0%, #44697d 100%);
    background: linear-gradient(to bottom, rgba(68, 105, 125, 0) 0%, #44697d 100%);
    position: absolute;
    bottom: 1em;
    width: 100%;
    height: 85%; }
  .card .selectBtn {
    background: #3CB6CE;
    color: #fff;
    padding: 0.3em 0.7em;
    position: absolute;
    bottom: 1.25em;
    right: 1.25em;
    display: block; }
    .card .selectBtn span {
      display: flex;
      align-items: center; }
    .card .selectBtn #icon.icon-check-circle {
      font-size: 65%;
      margin: 0 0.5em 0 0;
      fill: #fff; }
  .card .info {
    -webkit-transition: opacity 0.40s ease-out;
    transition: opacity 0.40s ease-out;
    color: #fff;
    padding: 1.25em;
    position: absolute;
    bottom: 0;
    left: 0;
    line-height: 1.3;
    width: 60%; }
    .card .info h4 {
      font-size: 120%;
      line-height: 1.2;
      font-weight: 700; }
    .card .info .label {
      background: #FFCB23;
      color: #37424A;
      text-transform: uppercase;
      font-size: 70%;
      padding: 0.2em 0.4em 0;
      display: inline-block;
      font-weight: 500;
      margin: 0 0 0.5em 0; }
    .card .info .subinfo {
      font-weight: 200;
      font-size: 90%;
      opacity: 0.80;
      padding: 0.3em 0 0 0; }
      .card .info .subinfo p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .card .info .subinfo span {
        opacity: 0.80; }
      .card .info .subinfo strong {
        font-weight: 500; }

.card-class {
  width: auto;
  padding: 0;
  position: relative;
  display: block;
  overflow: visible;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border: 0.1em solid #44697D; }
  .card-class .selectBtn {
    background: #3CB6CE;
    color: #fff;
    padding: 0.5em 0.7em;
    display: inline-block; }
    .card-class .selectBtn span {
      display: flex;
      align-items: center; }
    .card-class .selectBtn #icon.icon-check-circle {
      font-size: 65%;
      margin: 0 0.5em 0 0;
      fill: #fff; }
  .card-class .info {
    -webkit-transition: opacity 0.40s ease-out;
    transition: opacity 0.40s ease-out;
    padding: 1.25em 0;
    line-height: 1.3;
    width: 60%;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    .card-class .info h4 {
      font-size: 120%;
      line-height: 1.2;
      font-weight: 700; }
    .card-class .info .subinfo {
      font-weight: 200;
      font-size: 90%;
      opacity: 0.80;
      padding: 0.3em 0 0 0; }
      .card-class .info .subinfo p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .card-class .info .subinfo span {
        opacity: 0.80; }
      .card-class .info .subinfo strong {
        font-weight: 500; }

.card-selected .selectBtn {
  background: transparent;
  border: 0.3em solid #FFCB23;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }
  .card-selected .selectBtn span {
    position: absolute;
    padding: 0.3em 0.3em 0.1em 0.5em;
    color: #37424A;
    bottom: 0;
    right: 0;
    background: #FFCB23; }
    .card-selected .selectBtn span #icon.icon-check-circle {
      fill: #37424A; }

.appProfileDetails {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 1.5em 0;
  justify-content: flex-start; }
  .appProfileDetails .profilePic {
    width: 12%;
    padding: 0 3% 0 0; }
    .appProfileDetails .profilePic img {
      border-radius: 100em;
      height: 8em;
      width: 8em;
      margin: 0 auto;
      display: block; }
    .appProfileDetails .profilePic .icon-profile {
      font-size: 400%;
      opacity: 0.20; }
  .appProfileDetails .detailsList {
    text-align: left;
    width: 50%;
    display: flex;
    align-items: center; }
    .appProfileDetails .detailsList ul {
      line-height: 1.5;
      float: left;
      width: auto; }
      .appProfileDetails .detailsList ul .detailsName {
        font-size: 180%;
        font-weight: 500; }
      .appProfileDetails .detailsList ul li {
        font-weight: 100; }
        .appProfileDetails .detailsList ul li strong {
          font-weight: 500; }
    .appProfileDetails .detailsList ul:first-child {
      width: 35%;
      border-right: 1px solid rgba(0, 0, 0, 0.15);
      margin: 0 7% 0 0;
      padding: 0 5% 0 0; }
    .appProfileDetails .detailsList ul:second-child {
      width: auto; }
  .appProfileDetails .installGO {
    width: 35%; }
    .appProfileDetails .installGO img {
      width: 11em;
      display: block;
      margin: 0 0 0 auto; }

.appTableData {
  width: 100%;
  text-align: left;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 12em; }
  .appTableData .tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1em 0;
    border-bottom: 4px solid rgba(0, 0, 0, 0.1); }
    .appTableData .tableHeader h2 {
      font-size: 180%; }
    .appTableData .tableHeader .tableLink {
      font-weight: 700;
      font-size: 130%; }
  .appTableData .tableData {
    width: 100%; }
    .appTableData .tableData tr {
      vertical-align: middle; }
      .appTableData .tableData tr th {
        border-bottom: 4px solid rgba(0, 0, 0, 0.1);
        padding: 0.5em 0;
        font-size: 110%;
        text-align: left;
        align: left; }
      .appTableData .tableData tr td {
        font-weight: 100;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 1em 0;
        vertical-align: middle; }
        .appTableData .tableData tr td .previewVideo {
          display: flex;
          align-items: center; }
          .appTableData .tableData tr td .previewVideo #icon.icon-circle-play {
            font-size: 80%;
            margin: 0 0.5em 0 0; }
      .appTableData .tableData tr .lastColumn {
        text-align: right; }
    .appTableData .tableData tr:last-child td {
      border-bottom: none; }
    .appTableData .tableData .activePlan {
      background: #4DE491;
      margin: 0 0 0 0.5em;
      text-transform: uppercase;
      font-size: 90%;
      padding: 0.2em 0.5em 0.1em 0.2em;
      font-weight: bold;
      color: #fff; }

.planCalendarWeek {
  width: 100%; }
  .planCalendarWeek .calendarNav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    border-top: 0.1em solid rgba(0, 0, 0, 0.1);
    border-bottom: 0.1em solid rgba(0, 0, 0, 0.1); }
    .planCalendarWeek .calendarNav .calendarNavCurrent {
      font-size: 90%; }
    .planCalendarWeek .calendarNav .weekTitle {
      color: rgba(0, 0, 0, 0.6); }
    .planCalendarWeek .calendarNav .calendarNavNext,
    .planCalendarWeek .calendarNav .calendarNavPrev {
      padding: 1em 0;
      display: flex;
      align-items: center;
      font-weight: 700;
      background-color: transparent; }
      .planCalendarWeek .calendarNav .calendarNavNext .disabled,
      .planCalendarWeek .calendarNav .calendarNavPrev .disabled {
        color: rgba(0, 0, 0, 0.1); }
      .planCalendarWeek .calendarNav .calendarNavNext #icon,
      .planCalendarWeek .calendarNav .calendarNavPrev #icon {
        font-size: 80%;
        margin: 0 0.7em; }
    .planCalendarWeek .calendarNav .calendarNavNext {
      -webkit-transform: rotateZ(180deg);
              transform: rotateZ(180deg); }
  .planCalendarWeek .calendarContent {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto; }
    .planCalendarWeek .calendarContent .day {
      text-align: center;
      background: rgba(0, 0, 0, 0.03);
      font-size: 85%;
      border: 2px solid rgba(0, 0, 0, 0.1);
      box-sizing: border-box;
      position: relative;
      padding: 0 0 3.5em 0;
      width: 14.28%;
      min-width: 9.5em; }
      .planCalendarWeek .calendarContent .day .dayNumber {
        font-size: 350%;
        font-weight: 100;
        padding: 0.3em 0 0 0;
        line-height: 1; }
      .planCalendarWeek .calendarContent .day .dayName {
        font-size: 100%;
        text-transform: uppercase;
        letter-spacing: 2px;
        padding: 0 0 0.8em 0; }
      .planCalendarWeek .calendarContent .day .dayImage {
        overflow: hidden;
        height: 12vh; }
        .planCalendarWeek .calendarContent .day .dayImage img {
          width: 100%;
          display: block;
          object-fit: cover;
          height: 100%; }
      .planCalendarWeek .calendarContent .day .dayTheme {
        background: rgba(0, 0, 0, 0.75);
        padding: 0.5em 0;
        color: #fff;
        text-transform: uppercase;
        letter-spacing: -0.02em; }
      .planCalendarWeek .calendarContent .day .dayTopActions {
        font-size: 90%; }
        .planCalendarWeek .calendarContent .day .dayTopActions button {
          padding: 0.8em 0 0.7em;
          color: #fff;
          width: 100%;
          font-weight: 700;
          font-size: 120%;
          text-transform: uppercase; }
        .planCalendarWeek .calendarContent .day .dayTopActions .preview {
          background: #3CB6CE; }
        .planCalendarWeek .calendarContent .day .dayTopActions .change {
          background: #3CB6CE;
          font-weight: 600; }
      .planCalendarWeek .calendarContent .day .dayTitle,
      .planCalendarWeek .calendarContent .day .dayTimeLocation,
      .planCalendarWeek .calendarContent .day .dayDuration,
      .planCalendarWeek .calendarContent .day .dayType,
      .planCalendarWeek .calendarContent .day .dayIntensity {
        text-overflow: ellipsis;
        overflow: hidden;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0.5em 0;
        color: rgba(0, 0, 0, 0.6); }
      .planCalendarWeek .calendarContent .day .dayIntensity {
        display: flex;
        align-items: center;
        justify-content: center; }
        .planCalendarWeek .calendarContent .day .dayIntensity #icon {
          font-size: 80%;
          margin: 0 0.5em 0 0; }
      .planCalendarWeek .calendarContent .day .dayType {
        border: none; }
      .planCalendarWeek .calendarContent .day .dayTitle {
        white-space: normal; }
      .planCalendarWeek .calendarContent .day .dayBottomAction {
        position: absolute;
        width: 100%;
        bottom: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: stretch;
        align-content: stretch; }
        .planCalendarWeek .calendarContent .day .dayBottomAction a, .planCalendarWeek .calendarContent .day .dayBottomAction button {
          text-transform: uppercase;
          text-decoration: none;
          border-top: 3px solid rgba(0, 0, 0, 0.1);
          font-weight: 700;
          padding: 0.9em 0;
          background: rgba(0, 0, 0, 0);
          color: #fff;
          width: 100%; }
          .planCalendarWeek .calendarContent .day .dayBottomAction a #icon, .planCalendarWeek .calendarContent .day .dayBottomAction button #icon {
            font-size: 75%;
            margin: 0 0.3em 0 0;
            background: #44697D; }
        .planCalendarWeek .calendarContent .day .dayBottomAction .dayButton {
          width: 50%; }
        .planCalendarWeek .calendarContent .day .dayBottomAction .preview {
          background: #44697D; }
        .planCalendarWeek .calendarContent .day .dayBottomAction .edit {
          background: #3CB6CE; }
    .planCalendarWeek .calendarContent .dayRest {
      background: transparent; }
      .planCalendarWeek .calendarContent .dayRest .dayBottomAction {
        position: absolute;
        bottom: 0; }
      .planCalendarWeek .calendarContent .dayRest .dayTheme {
        opacity: 0.60; }
    .planCalendarWeek .calendarContent .dayCurrent {
      border-color: #FFCB23;
      border-width: 3px;
      background: rgba(255, 203, 35, 0.15); }
      .planCalendarWeek .calendarContent .dayCurrent .dayTheme {
        background: #FFCB23;
        opacity: 1; }
      .planCalendarWeek .calendarContent .dayCurrent .dayNumber {
        font-weight: 500; }
      .planCalendarWeek .calendarContent .dayCurrent .dayName {
        font-weight: 700; }
      .planCalendarWeek .calendarContent .dayCurrent .dayTheme {
        font-weight: 700; }
    .planCalendarWeek .calendarContent .dayCompleted {
      border-color: #4DE491;
      border-width: 3px;
      background: rgba(77, 228, 145, 0.15); }
      .planCalendarWeek .calendarContent .dayCompleted .dayTheme {
        background: #4DE491; }

.addTask {
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  height: 2.9em; }

.restDayAdd {
  background-color: transparent;
  position: absolute;
  bottom: 0;
  margin-bottom: 10px;
  width: 26px;
  height: 26px;
  margin-left: -15px;
  margin-top: -3px !important; }

.confettiBlast {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: auto;
  margin: 0 auto;
  height: 100%;
  display: block; }

.timeButton {
  background: transparent;
  color: #3CB6CE; }

@media print {
  body,
  .appUserDashboard {
    padding: 0 !important;
    margin: 0 !important; }
  .header {
    display: none !important; }
  .appProfileDetails {
    margin: 30px 0 0 0; }
  .profilePic,
  .tableLink,
  .appUserDashboard .fatSepFull,
  .progressMeters,
  .appBackground,
  .appTableWorkoutHistory,
  .appTableMyPlans,
  .dayBottomAction,
  .printBtn {
    display: none !important; }
  .appUserDashboard .currentPlan {
    margin-top: 1em !important;
    padding-top: 0 !important;
    border-top: 2px solid #ddd !important; }
  .appUserDashboard .detailsList {
    width: 100% !important; }
  .appUserDashboard .detailsList ul {
    width: 50% !important; }
  .day {
    padding: 0 !important; }
  .dayCurrent {
    border-width: 3px !important;
    border-color: #000 !important; }
  .dayImage {
    height: 6em !important; }
  .dayTheme {
    color: #000 !important;
    opacity: 1 !important;
    border-top: 2px solid #ddd !important;
    border-bottom: 2px solid #ddd !important; }
  /*
  .progressBar {
    box-shadow: inset 0 0 0 1000px #777;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#777777', endColorstr='#777777')";
  }
  .progressCount {
    padding-left:15px;
  }
  .progressWorkouts,
  .progressDays {
    box-shadow: inset 0 0 0 1000px #ddd;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ddd', endColorstr='#ddd')";
  }
  */
  .printFix .appProgressSteps {
    display: none !important; }
  .appFullModal {
    overflow: auto !important;
    position: absolute !important;
    top: 0 !important;
    bottom: auto !important;
    left: 0 !important;
    right: auto !important;
    height: auto;
    box-shadow: inset 0 0 0 5000px #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#fff', endColorstr='#fff')"; }
  .icon-play {
    display: none !important; }
  .changeDayModal .planPreviewModal .previewTop .previewLeft {
    width: 100% !important;
    font-size: 75% !important; }
  .previewRight {
    display: none !important; }
  .appFullModal .modalWrapper {
    height: auto !important;
    display: block;
    position: relative; }
  .modalHeader {
    display: none !important; }
  .appTableData .tableData tr td {
    padding: 3px 0; }
  .previewTop #icon {
    font-size: 130%; }
  .previewTop .publisher {
    width: 2em; } }

.planDebugger {
  background-color: orange;
  top: 100px;
  left: 100px;
  position: absolute;
  z-index: 2000;
  font-size: 12px;
  text-align: left; }

@media only screen and (min-width: 600px) {
  .planSteps {
    align-items: center; } }

@media only screen and (max-width: 600px) {
  .appUsers .or {
    padding: 2em 0 0 0;
    margin: 2em 0 0 0; }
    .appUsers .or p {
      border-left: none;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      width: 100%;
      height: auto; }
      .appUsers .or p span {
        left: auto;
        top: -1em;
        width: 2em;
        text-align: center;
        justify-content: center;
        align-items: center; } }

@media only screen and (max-width: 900px) {
  .debugLinks {
    display: none !important; }
  .planCalendarWeek .calendarContent {
    flex-direction: column; }
    .planCalendarWeek .calendarContent .day {
      width: 100%;
      margin: 1em 0;
      border-radius: 10px; }
      .planCalendarWeek .calendarContent .day .dayImage {
        height: 20vh; }
      .planCalendarWeek .calendarContent .day .dayBottomAction .preview {
        border-radius: 0 0 0 7px; }
      .planCalendarWeek .calendarContent .day .dayBottomAction .edit {
        border-radius: 0 0 7px 0; }
    .planCalendarWeek .calendarContent .dayRest {
      padding-bottom: 3em; }
  .appProfileDetails {
    flex-direction: column; }
    .appProfileDetails .installGO {
      display: none; }
    .appProfileDetails .profilePic {
      width: auto;
      padding: 0; }
    .appProfileDetails .detailsList {
      width: 100%;
      display: block;
      text-align: center; }
      .appProfileDetails .detailsList ul {
        margin: 0 !important;
        width: 100% !important;
        padding: 0 !important;
        border: none !important; }
  .appOverflowContent {
    padding: 0 1.5em; }
    .appOverflowContent .fatSepFull {
      margin: 0 0 0 -6em;
      padding: 0 9em 0 0 0; }
  .appUserDashboard .currentPlan h2 {
    width: 47%;
    font-size: 130%; }
  .appUserDashboard .currentPlan .planSummary {
    width: 45%;
    padding: 0 0 0 5%; }
    .appUserDashboard .currentPlan .planSummary .progressMeters {
      display: none; }
  .appProgressSteps {
    padding: 0 1.5em 0;
    height: 100%;
    align-items: flex-start; }
    .appProgressSteps .stepContent {
      padding: 2em 0 4em 0; }
    .appProgressSteps .listItem {
      padding-right: 5px; }
    .appProgressSteps .previousBtn, .appProgressSteps .nextBtn {
      -webkit-transform: none;
              transform: none;
      top: auto;
      bottom: 0;
      left: 0;
      right: auto;
      border-radius: 0;
      height: 3.25em;
      padding: 0;
      width: 50%;
      background: #3CB6CE; }
      .appProgressSteps .previousBtn #icon, .appProgressSteps .nextBtn #icon {
        fill: #fff;
        height: 1.1em;
        width: 1.1em; }
    .appProgressSteps .previousBtn {
      width: 15%;
      background: #37424A; }
    .appProgressSteps .nextBtn {
      right: 0;
      left: auto;
      width: 85%;
      display: flex;
      flex-direction: row; }
      .appProgressSteps .nextBtn .nextText {
        font-size: 110%; }
    .appProgressSteps .formDaysSelect {
      flex-direction: column; }
      .appProgressSteps .formDaysSelect .daySelected {
        width: 100%;
        padding: 1em 0; }
      .appProgressSteps .formDaysSelect .day {
        max-width: none;
        width: 100%;
        margin: 0 0 1em;
        width: 100%;
        padding: 1em 0; }
    .appProgressSteps .formDaysSelected .day, .appProgressSteps .formDaysSelected .daySelected {
      width: 90%;
      padding: 1em 5%; }
  .planPreviewModal .appTableData {
    padding: 0 1.5em; }
  .planPreviewModal .previewTop .previewLeft {
    width: 100%; }
    .planPreviewModal .previewTop .previewLeft h1 {
      font-size: 160%;
      padding: 1em 1.5em 0; }
    .planPreviewModal .previewTop .previewLeft ul {
      padding-bottom: 2em; }
      .planPreviewModal .previewTop .previewLeft ul li {
        padding: 0 1em 0 0; }
        .planPreviewModal .previewTop .previewLeft ul li .value {
          font-size: 100%; }
        .planPreviewModal .previewTop .previewLeft ul li .label {
          font-size: 80%; }
        .planPreviewModal .previewTop .previewLeft ul li .publisher {
          height: 2em; }
        .planPreviewModal .previewTop .previewLeft ul li #icon {
          font-size: 110%; }
      .planPreviewModal .previewTop .previewLeft ul li:last-child {
        display: none; }
  .planPreviewModal .previewTop .previewRight {
    display: none; }
  .planPreviewModal .previewTopExtra {
    padding: 0 1.5em; }
  .appUsers {
    height: 100%;
    min-height: calc(100vh - 4.4em);
    padding: 0 1.5em; }
    .appUsers h1 {
      padding: 0;
      font-size: 210%; }
    .appUsers h2 {
      padding: 0;
      font-size: 100% !important; }
  .appModal .modalWrapper {
    padding: 1.5em;
    max-width: 75%; }
    .appModal .modalWrapper .modalContent .modalTitle {
      font-size: 150%; }
    .appModal .modalWrapper .modalContent button {
      margin-bottom: 0.2em; }
  .changeDayModal .previewLeft {
    font-size: 90%;
    overflow-y: auto;
    padding: 1.5em 2.5% 0; }
  .changeDayModal .resultsRight .rightHeader {
    padding: 1em 0; }
  .changeDayModal .resultsRight .replacementText {
    padding: 1em 1.25em;
    font-size: 90%; }
  .changeDayModal .resultsRight .searchInput {
    position: relative;
    margin-top: -1em;
    top: auto;
    right: auto;
    padding: 0 1.5em;
    width: auto;
    align-items: flex-start; }
  .changeDayModal .resultsRight .resultsCount {
    font-size: 100%;
    padding: 0 1em; }
  .changeDayModal .resultsRight .resultsBox .spacer {
    padding: 0; }
  .changeDayModal .resultsRight .workoutCard {
    font-size: 90%;
    width: 100% !important;
    margin: 0 0 0.1em 0 !important;
    height: 30vh !important; }
    .changeDayModal .resultsRight .workoutCard .workoutTitle {
      font-size: 120% !important;
      line-height: 1.2; }
    .changeDayModal .resultsRight .workoutCard .workoutDetails {
      width: 55%;
      flex-direction: column-reverse; }
      .changeDayModal .resultsRight .workoutCard .workoutDetails .streamDetails strong {
        display: none; }
    .changeDayModal .resultsRight .workoutCard .ctas {
      position: absolute;
      bottom: 0;
      top: auto;
      right: 0em;
      width: 5em; }
      .changeDayModal .resultsRight .workoutCard .ctas button {
        margin: 0 0 0 0;
        text-align: center;
        width: 100%;
        position: relative;
        padding: 0.6em 0;
        top: auto;
        bottom: auto;
        right: auto;
        font-weight: 600; }
      .changeDayModal .resultsRight .workoutCard .ctas .workoutCardPreview {
        margin: 0 0 0.2em 0;
        background: #44697D;
        font-weight: 300; }
  .appSplash .versionNumber {
    opacity: 0.40; }
  .appSplash .bottom {
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    height: 100%;
    padding: 2em 1.5em;
    width: auto;
    background: -webkit-linear-gradient(top, rgba(55, 66, 74, 0) 0%, #37424a 100%);
    background: linear-gradient(to bottom, rgba(55, 66, 74, 0) 0%, #37424a 100%); }
    .appSplash .bottom .logo {
      width: 30%;
      margin: 0; }
    .appSplash .bottom .copy {
      padding: 0;
      width: 100%; }
      .appSplash .bottom .copy h1 {
        font-size: 210%; }
      .appSplash .bottom .copy h2 {
        font-size: 110%;
        font-weight: 500;
        padding: 0.5em 5%; }
    .appSplash .bottom .cta {
      padding: 1em 0 0.5em 0;
      width: 100%; }
      .appSplash .bottom .cta button {
        font-size: 140%; }
  .appCenterMessage {
    justify-content: flex-start; }
    .appCenterMessage h1 {
      font-size: 200%; }
    .appCenterMessage h2 {
      font-size: 100%; }
    .appCenterMessage .planFeatures {
      flex-direction: column;
      border: none;
      width: 100%;
      padding: 0; }
      .appCenterMessage .planFeatures #icon {
        margin: 0 0.5em 0 0;
        font-size: 100%; }
      .appCenterMessage .planFeatures .planFeaturesTitle {
        display: none; }
      .appCenterMessage .planFeatures p {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0 0 0.5em 0;
        margin: 0 0 0.5em 0; }
      .appCenterMessage .planFeatures p:last-child {
        border: none;
        margin: 0;
        padding: 0; }
  .button-teal,
  .button-blue {
    font-size: 120%;
    padding: 0.6em 1.2em; }
  .appBackground #icon.icon-gologo {
    width: 55vh;
    right: -18%; }
  .appBackground .vidIntro {
    display: none; }
  .appTableData {
    font-size: 85%; }
    .appTableData .icon-play {
      display: none !important; }
    .appTableData .tableData td {
      /*
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width:12em;
        */ }
  .appTableData.plans, .appTableData.workoutHistory {
    display: block; }
    .appTableData.plans thead, .appTableData.plans tbody, .appTableData.plans th, .appTableData.plans td, .appTableData.plans tr, .appTableData.workoutHistory thead, .appTableData.workoutHistory tbody, .appTableData.workoutHistory th, .appTableData.workoutHistory td, .appTableData.workoutHistory tr {
      display: block; }
    .appTableData.plans .tbody tr, .appTableData.workoutHistory .tbody tr {
      border-bottom: 4px solid rgba(0, 0, 0, 0.1); }
    .appTableData.plans td, .appTableData.workoutHistory td {
      border: none;
      border-bottom: 1px solid #ccc !important;
      position: relative;
      padding-left: 50% !important;
      text-align: left; }
    .appTableData.plans td:before, .appTableData.workoutHistory td:before {
      position: absolute;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-size: 110%;
      font-weight: 400; }
  .appTableData.plans td:nth-of-type(1):before {
    content: "Plan Name"; }
  .appTableData.plans td:nth-of-type(2):before {
    content: "Started"; }
  .appTableData.plans td:nth-of-type(3):before {
    content: "Ended"; }
  .appTableData.plans td:nth-of-type(4):before {
    content: "Workouts"; }
  .appTableData.plans td:nth-of-type(5):before {
    content: "Completed"; }
  .appTableData.workoutHistory td:nth-of-type(1):before {
    content: "Date"; }
  .appTableData.workoutHistory td:nth-of-type(2):before {
    content: "Start Time"; }
  .appTableData.workoutHistory td:nth-of-type(3):before {
    content: "Duration"; }
  .appTableData.workoutHistory td:nth-of-type(4):before {
    content: "Name"; }
  .appTableData.workoutHistory td:nth-of-type(5):before {
    content: "Type"; }
  .printBtn {
    display: none !important; } }

