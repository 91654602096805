@import '../App/Vars.scss';

.icon-play {
    top: 5px;
    fill: #44697D;
    padding-right:10px;
    color: #44697D;
}
.video-link{
      color:$blue;
      font-weight:700;
      text-decoration:underline;
  }
