@import '../App/Vars.scss';


.appUserDashboard {


  .appProfileDetails {
    max-width:1200px;
  }

  .currentPlan {
    width:100%;
    padding:1em 0 0 0;
    max-width:1200px;
    margin:-2em 0 0 0;
    display:flex;
    justify-content: flex-start;
    align-items:stretch;
    position:relative;

    h2 {
      font-size:180%;
      font-weight:700;
      width:22%;
      padding:1em 3% 1em 0;
      align-items:center;
      display:flex;
      border-right: 2px solid rgba(0,0,0,0.1);

    }
    .planSummary {
      width:70%;
      padding:0 0 0 3%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      ul {
        padding:1.7em 0;
        line-height:1.9;
      }
      .progressMeters {
        padding:0 0 0 2em;
        width:50%;

        .progressBar {
          background:$blue;
          transition:width 1s ease-out;
          width:2%;
          display:block;
          position:absolute;
          left:-0.3em;
          top:0;
          height:100%;
          transform: skew(-9deg, 0deg) scaleX(0.50);
          transform-origin:0 0;
          // max-width:15.5em;
          padding-left:1.5em;
        }
        .progressCount {
          position:absolute;
          right:0;
          top:20%;
          text-transform:uppercase;
          font-size:130%;
          font-weight:100;
        }
        .progressWorkouts,
        .progressDays {
          height:2.5em;
          width:100%;
          padding:0;
          margin:0 0 0.1em 0;
          position:relative;
          overflow:hidden;
          display:flex;
          align-items:center;
          justify-content: flex-end;

          background: -moz-linear-gradient(left, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.1) 1%, rgba(0,0,0,0) 100%);
          background: -webkit-linear-gradient(left, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.1) 1%,rgba(0,0,0,0) 100%);
          background: linear-gradient(to right, rgba(0,0,0,0.1) 0%,rgba(0,0,0,0.1) 1%,rgba(0,0,0,0) 100%);
          filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a000000', endColorstr='#00000000',GradientType=1 );
        }
        .progressWorkouts {
          .progressBar {
            background:$blue;
            // simulate
            width:20%;
          }
        }
        .progressDays {
          .progressBar {
            background:$darkblue;
            width:100%;
          }
        }
      }
      .printBtn {
          background-color: transparent;
        position:absolute;
        right:0;
        // display:none;

        .icon-print {
          padding:1em 0 0 0;
          font-size:120%;
        }
      }
    }
  }
  .planCalendarWeek {
    padding:0 0 2em 0;
  }
  .appTableData {

  }
}
