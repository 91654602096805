@import '../App/Vars.scss';

.appModal {
  background:rgba(55,66,74,0.85);
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  width:100%;
  height:100%;
  z-index:1000;
  overflow: hidden;
  text-align:center;

  .modalVideoWrapper {
    background-color: #fff;
    margin: 0 auto;
    padding: 0;
    display: inline-block;
    text-align: left;

    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    .closeButton {
      z-index: 9000;
      position:absolute;
      top:-0.2em;
      padding:0.3em 0.4em;
      transform:scaleY(0.75);
      font-size:250%;
      font-weight:100;
      background:$white;
      right:0;
      color:rgba(0,0,0,0.40);
      text-decoration:none;
      border:1px solid rgba(0,0,0,0.10);
    }

    .modalContent {
      text-align:center;

      .modalTitle {
        margin:0 0 0.3em 0;
        padding:0;
      }
      .modalSubTitle {
        margin:0 0 1.5em 0;
        font-weight:100;
        padding:0;
      }
      button {
        margin:0 0.3em 0 0;
      }

    }
  }
  .modalWrapper {
    background-color: #fff;
    margin:0 auto;
    padding: 4em;
    max-width:65%;
    max-height:65%;
    overflow:auto;
    display:inline-block;
    text-align:left;

    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    .closeButton {
      z-index: 9000;
      position:absolute;
      top:-0.2em;
      padding:0.3em 0.4em;
      transform:scaleY(0.75);
      font-size:250%;
      font-weight:100;
      background:$white;
      right:0;
      color:rgba(0,0,0,0.40);
      text-decoration:none;
      border:1px solid rgba(0,0,0,0.10);
    }

    .modalContent {
      text-align:center;

      .modalTitle {
        margin:0 0 0.3em 0;
        padding:0;
      }
      .modalSubTitle {
        margin:0 0 1.5em 0;
        font-weight:100;
        padding:0;
      }
      button {
        margin:0 0.3em 0 0;
      }

    }
  }


}

