/*#region Map Components Styling*/
.mapboxgl-ctrl-top-left .mapboxgl-ctrl {
    border-radius: 0;
    filter: drop-shadow(0px 1px 2px rgba(0,0,0,0.5));
    margin: 2rem 0 0 2rem !important;
  }
  .mapboxgl-ctrl-geocoder {
    font-family: 'Roboto Condensed' !important;
  }
  .mapboxgl-ctrl-geocoder--icon {
    fill: black !important;
  }
  .mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right {
    display: none;
  }
  
  
  .mapboxgl-ctrl-geocoder--input {
    height: 2.5rem !important;
  }
  .mapboxgl-ctrl-top-left .mapboxgl-ctrl {
    width: calc(100% - 20px) !important;
    margin: 10px 0 0 10px !important;
  }
  .mapboxgl-ctrl-top-left {
    width: 100% !important;
    max-width: 100% !important;
  }
  
  
  .mapboxgl-ctrl-geocoder--icon {
    top: 8px !important;
    left: 10px !important;
  }
  
  .mapboxgl-ctrl-geocoder--icon-close {
    margin-top: 3px !important;
    margin-right: 2px !important;
  }
  /*#endregion Map Components Styling*/