@import '../App/Vars.scss';


html{
    ms-touch-action: none;
    -ms-content-zooming: none;
    -ms-user-select: none;
    user-select: none;
}

/* resets */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1.2;
  font-family: 'Roboto Condensed', sans-serif;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
strong {
  font-weight:700;
}

.bold {
  font-weight: bold;
}
/* animations */





@keyframes zoomInYellow {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  40% {
    opacity: 0.50;
  }
  80% {
    opacity: 1;
    fill:$yellow;
  }
  100% {
    fill:$darkblue;
  }
}
.animate-zoomInYellow {
  animation-name: zoomInYellow;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay:0.6s;
}


@keyframes zoomInGreen {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  40% {
    opacity: 0.50;
  }
  80% {
    opacity: 1;
    fill:$green;
  }
  100% {
    fill:$darkblue;
  }
}
.animate-zoomInGreen {
  animation-name: zoomInGreen;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay:0.6s;
}



@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.animate-fadeInDown {
  animation-name: fadeInDown;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay:0.3s;
}



@keyframes slideUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100vh, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.animate-slideUp {
  animation-name: slideUp;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay:0s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.animate-fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay:0s;
}


@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
.animate-fadeOut {
  animation-name: fadeOut;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay:1.75s;
}



@keyframes fadeInCheck {
  from {
    opacity: 0;
		transform: scale3d(0.7, 0.7, 0.7);
  }
  to {
    opacity: 1;
		transform: scale3d(1,1,1);
  }
}
.animate-fadeInCheck {
  animation-name: fadeInCheck;
  animation-duration: 0.5s;
  animation-fill-mode: both;
  animation-timing-function: ease-in-out;
  animation-delay:2.5s;
}



.animate-delay-0-2 { animation-delay: 0.2s; }
.animate-delay-0-5 { animation-delay: 0.5s; }
.animate-delay-0-7 { animation-delay: 0.7s; }
.animate-delay-1-0 { animation-delay: 1.0s; }
.animate-delay-1-2 { animation-delay: 1.2s; }
.animate-delay-1-5 { animation-delay: 1.5s; }


// loading appLoadingSpinner

@keyframes loadSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// loading magicSpinner

@keyframes circleSpin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(720deg);
  }
  /*

  0% {
    transform: rotate(0deg);
  }
	25% {
	   transform: rotate(360deg);
	}
	50% {
		transform: rotate(720deg);
	}
	75% {
		transform: rotate(1080deg);
	}
	97% {
		border-top: 0.2em solid rgba(200, 200, 200, 0.2);
		border-right: 0.2em solid rgba(200, 200, 200, 0.2);
		border-bottom: 0.2em solid rgba(200, 200, 200, 0.2);
		border-left: 0.2em solid #C8C8C8;
		transform: rotate(1540deg);
	}
  100% {
		border-color:white;
		// filter: brightness(400%);
  }
  */
}
.animate-circle {
	animation: circleSpin 3s infinite linear;
	animation-fill-mode: forwards;
}


@keyframes iconEffect {
	0% {
		opacity:0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
	10% {
		opacity:1;
		transform: scale3d(0.7, 0.7, 0.7);
	}
	99% {
		opacity:1;
		transform: scale3d(1.2, 1.2, 1.2);
  }
  100% {
		opacity:0;
		transform: scale3d(0.3, 0.3, 0.3);
  }
}

.animate-iconEffect {
  animation-name: iconEffect;
  animation-duration: 2.5s;
  animation-fill-mode: both;
	animation-iteration-count: 1;
  animation-timing-function: ease-in;
}









/* mixins */








/* font-sizes */




.fontSize110 { font-size:110%; }
.fontSize120 { font-size:120%; }
.fontSize150 { font-size:150%; }
.fontSize200 { font-size:200%; }
.fontSize250 { font-size:250%; }
.fontSize300 { font-size:300%; }
.fontSize350 { font-size:350%; }







/* buttons */





button {
  outline:none;
  border:none;
  cursor:pointer;
  margin-right:0.3em;
}
.button-teal {
  background:$teal;
  color:$white;
  font-weight:700;
  display:inline-block;
  text-decoration:none;
  padding:0.8em 1.25em;
  font-size:130%;
  text-align:center;
}
.button-blue {
  background:$blue;
  color:$white;
  font-weight:500;
  display:inline-block;
  text-decoration:none;
  padding:0.8em 1.25em;
  font-size:130%;
  text-align:center;
}
.button-skewed {
  transform:skewX(-8deg);
  border-radius:3px;
}
.button-mission {
    margin-right:20px;
}
.button-pill {
	border-radius:300px;
	text-transform:uppercase;
	border:1px solid $teal;
	padding:0.3em 0.7em 0.2em;
	font-size:85%;
	background:none;
}
a {
	color:$blue;
}





/* icons */







#icon, .icon {
	width:2em;
  height:2em;
  position:relative;
  display:inline-block;
	fill:$darkblue;

	path {

	}
}
.iconDisabled {
	width:2em;
  height:2em;
  position:relative;
  margin: 0 0.4em;
    color: rgba(0,0,0,0.3);
}

.icon-circle-play {

}
.icon-circle-add {

}
.icon-circle-left {

}
.icon-circle-right {

}
.icon-check {
	-webkit-mask-size:160%;

}
.icon-check-circle {

}
.icon-location-ios {

}
.icon-arrow-down {
  -webkit-mask-size:70%;

}
.icon-arrow-left {

}
.icon-arrow-right {
	// have absolutely no idea why i need this
	transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH";
}

.icon-calendar {
	transform: scale(0.85);
}
#icon.icon-trainer {

}
#icon.icon-sun {

}
#icon.icon-classes {

}
#icon.icon-level {

}
#icon.icon-target {

}
#icon.icon-close {
  // again, no idea why i need this
  transform: rotate(45deg);
}
#icon.icon-search {

}
#icon.icon-print {

}
#icon.icon-intensity-low {
  padding:.35em 0;
  height:1.3em;
}
#icon.icon-intensity-medium {
  padding:.35em 0;
  height:1.3em;
}
#icon.icon-intensity-high {
  padding:.35em 0;
  height:1.3em;
}
#icon.icon-house {

}
#icon.icon-clock {

}









// inputs




.inputInlineWrapper {
  display:inline-block;
  margin:0 0.5em 0 0;
  // ie11 fix
  .checkin_code {
    flex-direction:row;
  }
}
.inputBlockWrapper {
  display:block;
  margin:0 0.5em 0 0;
}
.inputFormWrapper {
  overflow:hidden;
  clear:both;
  // only children of parent
  > .inputInlineWrapper {
    float:left;
    padding:0.5em 0 1em 0;
  }
  .button-teal {
    clear:both;
  }
  .checkin_code {
    margin:0;
  }

}




// separators




.fatSep {
	height:1em;
	width:100%;
	display:block;

	background: -moz-linear-gradient(top, rgba(55,66,74,0.1) 0%, rgba(55,66,74,0) 100%);
	background: -webkit-linear-gradient(top, rgba(55,66,74,0.1) 0%,rgba(55,66,74,0) 100%);
	background: linear-gradient(to bottom, rgba(55,66,74,0.1) 0%,rgba(55,66,74,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#1a37424a', endColorstr='#0037424a',GradientType=0 );

}







// shared CSS across multiple components




// PARENT screen level styles



// global loading barrier

.appLoader {
  background:rgba(55,66,74,0.85);
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  width:100%;
  height:100%;
  z-index:100;
  overflow: hidden;
  text-align:center;

	.appLoadingSpinner {
			position:fixed;
			top:50%;
			left:50%;
			width:8em;
			height:6em;
			margin:-2em 0 0 -4em;
			text-align:center;

			.loader, .loader:after {
			  border-radius: 50%;
			  width: 4em;
			  height: 4em;
			}
			.loader {
			  margin: 0 auto 20px auto;
			  font-size: 10px;
			  position: relative;
			  text-indent: -9999em;
			  border-top: 0.3em solid rgba(200, 200, 200, 0.2);
			  border-right: 0.3em solid rgba(200, 200, 200, 0.2);
			  border-bottom: 0.3em solid rgba(200, 200, 200, 0.2);
			  border-left: 0.3em solid #C8C8C8;
			  -ms-transform: translateZ(0);
			  transform: translateZ(0);
			  animation: loadSpinner 1.1s infinite linear;
			}
			.loadingText {
				font-size:90%;
				display:block;
				letter-spacing:0.1em;
				text-transform:uppercase;
				color:$white;
			}
	}


	.appMagicSpinner {
			position:fixed;
			margin:0 auto;

			/*
			top:50%;
			left:50%;
			margin:-5em 0 0 -10em;
			width:20em;
			height:10em;
			*/
			text-align:center;

			position: relative;
	    top: 50%;
	    -webkit-transform: translateY(-50%);
	    -ms-transform: translateY(-50%);
	    transform: translateY(-50%);

			.circle, .circle:after {
			  border-radius: 50%;
			  width: 7em;
			  height: 7em;
			}
			#icon.icon-gologo,
			#icon.icon-check {
				position:absolute;
				top:1.5em;
				left:50%;
				font-size:150%;
				margin:0 0 0 -23px;
				/*
				-webkit-transform: translateX(-50%);
		    -ms-transform: translateX(-50%);
		    transform: translateX(-50%);
				*/
				// background:$white;
				fill:$white;
			}
			#icon.icon-check {
				opacity:0;
			}
			.circle {
			  margin: 0 auto;
			  position: relative;
			  text-indent: -9999em;
			  border-top: 0.2em solid rgba(200, 200, 200, 0.2);
			  border-right: 0.2em solid rgba(200, 200, 200, 0.2);
			  border-bottom: 0.2em solid rgba(200, 200, 200, 0.2);
			  border-left: 0.2em solid #C8C8C8;
			  -ms-transform: translateZ(0);
			  transform: translateZ(0);
			}
			.loadingText {
				font-size:90%;
				margin:20px 0 0 0;
				display:block;
				letter-spacing:0.1em;
				text-transform:uppercase;
				color:$white;
			}
	}

}




// re-used full screen modal
// used for: planDayModal, planDayPreview


.appFullModal {
  background:rgba(55,66,74,0.85);
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  width:100%;
  height:100%;
  z-index:100;
  overflow: hidden;
  text-align:center;

  .modalHeader {
    height:10vh;
    width:100%;
    display:flex;
    justify-content: space-between;
    align-items: center;

    #icon.icon-close {
      // background:$white;
			fill:#fff;
      margin:0 0 0 0.75em;
      font-size:75%;
    }

    .leftSide,
    .rightSide {

      background-color: rgba(0, 0, 0, 0.0);
      color:$white;
      padding:0 3%;
      text-decoration:none;
    }
    .leftSide {
      font-size:150%;
      width:70%;
      text-align:left;
    }
    .rightSide {
      display:flex;
      align-items:center;
      justify-content: flex-end;
      flex-direction: row;
    }

  }

  .modalWrapper {
    background-color: #fff;
    margin:0 auto;
    padding:0;
    width:100%;
    height:90vh;
    display:inline-block;
    text-align:left;

    overflow:auto;
    box-shadow:0 -10px 65px 0 rgba(0, 0, 0, 0.25);

    .modalContent {
      text-align:left;
      overflow:hidden;

    }
  }
  .mobileModalWrapper {
    height: calc(100% - 10vh);

    .modalContent {
      height: 100%;
    }
  }
}



// used on profile/intermission
// users/success

.appCenterMessage {
  // @shawn not sure why we need this? it's breaking layout
  // margin-top:100px;
  height:95vh; // for IE11
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  // padding:0 10% 0;
  text-align:center;
  // kind of an ie11 hack, they ignore this
  min-height: calc(100vh - 4.4em);
  max-height: calc(100vh - 4.4em);


  .topIcon {
    font-size:420%;
  }
  h1 {
    font-weight:100;
    font-size:250%;
    margin:0.5em 0 0.3em;
  }
  h2 {
    font-size:130%;
    font-weight:100;
    opacity:0.50;
    margin:0 0 1.5em;
  }
  .bottomRightBtn {
    position:absolute;
    bottom:0;
    right:0;
    padding:2em;
    color:$gray;
  }
  @media (max-width: 600px), (max-height: 700px) {
    .bottomRightBtn {
      position: relative;
    }
  }
  .planFeatures {
      margin-top:150px;
    display:flex;
    border:1px solid rgba(0,0,0,0.10);
    border-radius:100em;
    padding:2em 3.2em 2.5em;
    margin:0 0 2em 0;

    #icon {
      font-size:280%;
      margin:0.1em 0.2em 0.05em 0.2em;
      display:block;
    }

    .planFeaturesTitle {
      position:absolute;
      background:$white;
      color:rgba(0,0,0,0.30);
      font-size:80%;
      letter-spacing:2px;
      top:-0.75em;
      padding:0;
      display:flex;
      align-items:center;
      justify-content: center;
      margin:0 auto 0 -3em;
      height:1.5em;
      width:6em;
      left:50%;
    }
  }
}



// used on plans, profile components

.whatRecommend {
  font-size:70%;
  display:flex;
  align-items: center;
  justify-content: center;
  font-weight:bold;
  padding:0 0.75em 0 0.4em;
  margin:0 0 0 1em;
  background:$yellow;
  text-transform:uppercase;
  border-radius:3px;

  #icon {
    width:0.7em;
    height:0.7em;
    margin:0 0.2em;
  }
}

.appProgressSteps {

  height:95vh; // for ie11
  max-height: calc(100vh - 4.4em);
  min-height: calc(100vh - 4.4em);
  position:relative;
  display:flex;
  justify-content:center;
  //align-items:center;
  flex-direction:row;
  padding:0 10% 0;
  text-align:center;


  .progressBar {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:5px;
    background:$white;

    .progress {
      width:5%; // default start width
      height:100%;
      background:$yellow;
      transition:1s width ease-in-out;
    }
  }

  .previousBtn, .nextBtn {
    position:fixed;
    width:4.5em;
    height:8em;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;

    // ie11 fix
    top: 53%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);

    .nextText {
      color:$white;
      font-size:95%;
      font-weight:800;
      display:block;
      padding:0 0.5em 0 0.5em;
    }
    #icon {

    }
  }
  .previousBtn {
    left:0;
    border-radius:0 0.4em 0.4em 0;
    // background:#eee;
  }
  .nextBtn {
    right:0;
    border-radius:0.4em 0 0 0.4em;
    background:$teal;
		padding:0 0 0 0.3em;
    #icon {
      // background-color:$white;
			fill:$white;
    }
  }
  .nextBtnText {
    #icon {
      width:1.5em;
    }
  }

  .stepContent {
    // max-width:30em;
    position:relative;
    transition:0.5s all ease-in-out;
    /*
    not using this anymore
    left:-100vw;
    left:0;
    */
    width:100%;
    .topIcon {
      font-size:350%;
    }

    h1 {
      font-weight:100;
      font-size:200%;
      margin:0 0 0.5em;
    }
    h2 {
      font-weight:100;
      opacity:0.50;
      margin:0 0 1.5em;
    }

  }

  .formDaysSelect {
    display:flex;
    flex-direction: row;
    align-items:flex-start;
    padding:1em 0 0 0;
    justify-content: center;

    .day {
      padding:1em 1em;
      margin:0 0.2em;
      width:auto;
      border:1px solid rgba(0,0,0,0.10);
      border-radius:5px;
      text-decoration:none;
      color:$darkblue;
			max-width:9em;
      background:transparent;

      .whatRecommend {
        display:none;
      }

			.inputBlockWrapper {
				display: block;
		    overflow: hidden;
		    margin: 0.75em 0 0 0;
		    padding: 0;
		    position: relative;
			}
      .dayNumber {
        font-size:300%;
        font-weight:300;
      }
      .dayName {

      }
      .dayWhen, .dayWhere, .dayWhat {
				border-top: 0;
		    margin: 0;
		    padding: 1.5em 0 0 0;
		    font-size: 80%;
		    text-align: left;
		    overflow: hidden;
        select {
          width:100%;
        }
        label {
          display:block;
          padding:0 0 0.3em 0;
        }
      }

    }
    .daySelected {
      border:1px solid $darkblue;
			.dayName {
				padding-bottom:1em;
				border-bottom:0.1em solid rgba(0,0,0,0.10);
			}
    }
  }

}



// used in Plans, Dashboard (future)

.planDayModal {

  .modalContent {
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    align-items:stretch;
  }
  .editDayFilters {
    width:24%;
    padding:2em 3%;
    box-shadow: 3px 0px 0px 0px rgba(0,0,0,0.1);
  }
  .editDayContent {
    width:70%;
    padding:2em 0;

    .contentTitle {
      font-size:150%;
      padding:0 0 1em 3%;
      margin:0 0 1em 0;
      border-bottom:0.1em solid rgba(0,0,0,0.1);
    }

    .contentSearch {
      position:absolute;
      top:0;
      right:3%;
      padding:2em 0 0 0;
      display:flex;
      align-items:center;

      input {
        width:15em;
      }

      #icon.icon-search {
        font-size:75%;
        display:block;
        padding:0 1.5em 0 0;
      }
    }

    .contentCards {
      display:flex;
      flex-wrap:wrap;
      padding:0 3%;

      .card {
        width:48%;
        margin:0 2% 2% 0;
      }
      .card:nth-child(even) {
        width:50%;
        margin:0 0 2% 0;
      }
      .card-class {
        width:50%;
        margin:0 auto 0.75em auto;
      }
    }
  }

}


.modalContentLoadingOverlay {
    height: 90vh;
    width: 100%;
    position: absolute;
}


.previewDayModal{
    z-index:110;
}
.planPreviewModal {
    overflow-y:scroll !important;
    height:100%;
	.previewTop {
		display:flex;


		.previewLeft {
				width:70%;
        position:relative;

				h1 {
					font-size:220%;
					text-align:center;
					padding:1.25em 0 0 0;
					font-weight:500;
				}
        .printBtn {
          background-color:transparent;
          position:absolute;
          right:0;
          top:0;
          // display:none;

          .icon-print {
            padding:1.2em 0.75em 0 0;
            font-size:100%;
          }
        }
				ul {
					display:flex;
					flex-direction:row;
					justify-content: center;
					padding:1em 0 2.5em 0;

					li {
						text-align:center;
						padding:0 1em;
						max-width:20%;

						#icon {
							display:block;
							margin:0 auto;
							font-size:210%;
						}
						.publisher {
							width:auto;
							height:3.9em;
						}
						#icon.icon-intensity-medium,
						#icon.icon-intensity-low,
						#icon.icon-intensity-high {
							-webkit-mask-size:70%;
						}
						.value {
							display:block;
							margin:0 auto;
							font-weight:500;
							font-size:130%;
							padding:0.25em 0 0.1em 0;
							text-overflow: ellipsis;
							white-space: nowrap;
							overflow:hidden;
						}
						.label {
							display:block;
							margin:0 auto;
							text-transform:uppercase;
							font-weight:100;
							font-size:90%;
							letter-spacing:1px;
						}
					}
				}
		}
		.previewRight {
				width:30%;
        max-height:18em;
				img {
					display:block;
					width:100%;
					height:100%;
					object-fit: cover;
				}
		}

	}
	.previewTopExtra {
		border-top:2px solid rgba(0,0,0,0.10);
		padding:0 3em;
		margin:0 auto;
		ul {
			max-width:1200px;
			margin:0 auto;
			li {
				padding:1em 0;
				border-bottom:1px solid rgba(0,0,0,0.10);
				clear:both;
				overflow:hidden;
        line-height:1.5;
				p {
					float:left;
					padding:0 2em 0 0;
				}
			}
			li:last-child {
				border-bottom:none;
			}
		}
	}
	.appTableData {
		padding:0 3em;
		width:auto;
	}

}





// basic screen that scrolls, non-centered vertically




.appOverflowContent {
  height:100%;
  width:auto;
  position:relative;
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction:column;
  padding:0 3em 0;

  .fatSepFull {
    margin:0 0 0 -3em;
    padding:0 9em 0 0;
  }
}







// CHILD level styles
// (contained inside PARENT stuff)





// cards

// copy paste from 24go-client

.card {
  width:auto;
  padding:0;
  position:relative;
  display:block;
  overflow:visible;
  background:$blue;
  min-height:9em;


  .poster {
    display:block;
    width:100%;
    padding:0 0 1em 0;
    background:$blue;
    transition:opacity 0.40s ease-out;
    object-fit:cover;
  }
  .gradient {
    background:$blue-gradient-full;
    position:absolute;
    bottom:1em;
    width:100%;
    height:85%;
  }
  .selectBtn {
    background:$teal;
    color:$white;
    padding:0.3em 0.7em;
    position:absolute;
    bottom:1.25em;
    right:1.25em;
    display:block;
    span {
      display:flex;
      align-items: center;
    }
    #icon.icon-check-circle {
      font-size:65%;
      margin:0 0.5em 0 0;
      // background:$white;
			fill:#fff;
    }
  }
  .info {
    transition:opacity 0.40s ease-out;
    color:$white;
    padding:1.25em;
    position:absolute;
    bottom:0;
    left:0;
    line-height:1.3;
    width:60%;

    h4 {
      font-size:120%;
      line-height:1.2;
      font-weight:700;
    }
    .label {
      background:$yellow;
      color:$darkblue;
      text-transform:uppercase;
      font-size:70%;
      padding:0.2em 0.4em 0;
      display:inline-block;
      font-weight:500;
      margin:0 0 0.5em 0;
    }
    .subinfo {
      font-weight:200;
      font-size:90%;
      opacity:0.80;
      padding:0.3em 0 0 0;
      p {
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
      }
      span {
        opacity:0.80;
      }
      strong {
        font-weight:500;
      }
    }
  }
}

.card-class {
  width:auto;
  padding:0;
  position:relative;
  display:block;
  overflow:visible;
  display:flex;
  justify-content: space-around;
  align-items: center;
  border:0.1em solid $blue;

  .selectBtn {
    background:$teal;
    color:$white;
    padding:0.5em 0.7em;
    display:inline-block;

    span {
      display:flex;
      align-items: center;
    }
    #icon.icon-check-circle {
      font-size:65%;
      margin:0 0.5em 0 0;
      // background:$white;
			fill:#fff;
    }
  }
  .info {
    transition:opacity 0.40s ease-out;
    padding:1.25em 0;
    line-height:1.3;
    width:60%;
    display:flex;
    justify-content: center;
    flex-direction: column;

    h4 {
      font-size:120%;
      line-height:1.2;
      font-weight:700;
    }
    .subinfo {
      font-weight:200;
      font-size:90%;
      opacity:0.80;
      padding:0.3em 0 0 0;
      p {
        white-space:nowrap;
        text-overflow:ellipsis;
        overflow:hidden;
      }
      span {
        opacity:0.80;
      }
      strong {
        font-weight:500;
      }
    }
  }
}
.card-selected {

  .selectBtn {
    background:transparent;
    border:0.3em solid $yellow;
    top:0;
    left:0;
    right:0;
    bottom:0;
    span {
      position:absolute;
      padding:0.3em 0.3em 0.1em 0.5em;
      color:$darkblue;
      bottom:0;
      right:0;
      background:$yellow;
      #icon.icon-check-circle {
        // background:$darkblue;
				fill:$darkblue;
      }
    }
  }

}



// profile details

.appProfileDetails {
  width:100%;
  display:flex;
  align-items: center;
  flex-direction: row;
  padding:1.5em 0;
  justify-content: flex-start;

  .profilePic {
    width:12%;
		padding:0 3% 0 0;

    img {
      border-radius:100em;
      height:8em;
      width:8em;
      margin:0 auto;
      display:block;
    }
    .icon-profile {
      font-size:400%;
      opacity:0.20;
    }
  }
  .detailsList {
    text-align:left;
    width:50%;
		display:flex;
		align-items:center;

    ul {
      // columns: 2;
      line-height:1.5;
			float:left;
			width:auto;

      .detailsName {
        font-size:180%;
        font-weight:500;
      }
      li {
        font-weight:100;
        strong {
          font-weight:500;
        }
      }
    }
		ul:first-child {
			width:35%;
			border-right:1px solid rgba(0,0,0,0.15);
			margin:0 7% 0 0;
			padding:0 5% 0 0;
		}
		ul:second-child {
			width:auto;
		}
  }
	.installGO {
		width:35%;
		img {
			width:11em;
			display:block;
			margin:0 0 0 auto;
		}
	}
}



// table of data

.appTableData {
  width:100%;
  text-align:left;
	// border-top:4px solid rgba(0,0,0,0.10);
	max-width:1200px;
	margin:0 auto;
  min-height:12em;

	.tableHeader {
		display:flex;
		justify-content: space-between;
		align-items:center;
		padding:1em 0;
		border-bottom:4px solid rgba(0,0,0,0.10);

		h2 {
			font-size:180%;
		}
		.tableLink {
      font-weight:700;
      font-size:130%;
		}
	}

	.tableData {
		width:100%;
		// border-bottom:4px solid rgba(0,0,0,0.10);

		tr {
			vertical-align:middle;

			th {
				border-bottom:4px solid rgba(0,0,0,0.10);
				padding:0.5em 0;
				font-size:110%;
        text-align:left;
        align:left;
			}
			td {
				font-weight:100;
				border-bottom:1px solid rgba(0,0,0,0.10);
				padding:1em 0;
				vertical-align:middle;

				.previewVideo {
					display:flex;
					align-items:center;
					#icon.icon-circle-play {
						font-size:80%;
						margin:0 0.5em 0 0;
					}
				}
			}
			.lastColumn {
				text-align:right;
			}
		}
		tr:last-child {
			td {
				border-bottom:none;
			}
		}

    .activePlan {
      background:$green;
      margin:0 0 0 0.5em;
      text-transform:uppercase;
      font-size:90%;
      padding:0.2em 0.5em 0.1em 0.2em;
      font-weight:bold;
      color:$white;
    }
	}


}



// plan week calendar
// used on dashboard, plan Create

.planCalendarWeek {
	width:100%;
	.calendarNav {
		display:flex;
		align-items:center;
		justify-content: space-between;
    max-width:1200px;
    margin:0 auto;
		width:100%;
		border-top:0.1em solid rgba(0,0,0,0.10);
		border-bottom:0.1em solid rgba(0,0,0,0.10);

		.calendarNavCurrent {
			font-size:90%;
		}
        .weekTitle {

            color: rgba(0,0,0,0.6);
        }
		.calendarNavNext,
		.calendarNavPrev {
			padding:1em 0;
			display:flex;
			align-items:center;
			font-weight:700;
            background-color: transparent;
            .disabled {
                color: rgba(0,0,0,0.10)
            }
			#icon {
				font-size:80%;
				// background:$blue;
				margin:0 0.7em;
			}
		}
        .calendarNavNext {
            transform: rotateZ(180deg);
        }
	}
	.calendarContent {
		display:flex;
		align-items:stretch;
		justify-content: space-between;
		max-width:1200px;
		margin:0 auto;

		.day {
			text-align:center;
			background:rgba(0,0,0,0.03);
			// box-shadow: inset 0px 0px 0px 3px rgba(0,0,0,0.10);
			font-size:85%;
			border:2px solid rgba(0,0,0,0.10);
			box-sizing: border-box;
			position:relative;
			padding:0 0 3.5em 0;

			width:14.28%;

      // ie11 fix
      min-width:9.5em;

			// width:100% / 7;
			// not sure why this isn't working

			.dayNumber {
				font-size:350%;
				font-weight:100;
				padding:0.3em 0 0 0;
				line-height:1;
			}
			.dayName {
				font-size:100%;
				text-transform:uppercase;
				letter-spacing:2px;
				padding:0 0 0.8em 0;
				// border-bottom:2px solid rgba(0,0,0,0.10);
			}
			.dayImage {
				overflow:hidden;
				height:12vh;

				img {
					width:100%;
					display:block;
					object-fit:cover;
					height:100%;
				}
			}
			.dayTheme {
				background:rgba(0,0,0,0.75);
				padding:0.5em 0;
				color:$white;
				text-transform:uppercase;
        letter-spacing:-0.02em;

			}
			.dayTopActions {
				font-size:90%;
				// hiding for MVP
				//display:none;

				button {
					padding:0.8em 0 0.7em;
					color:$white;
					width:100%;
					font-weight:700;
					font-size:120%;
					text-transform:uppercase;
				}
				.preview {
					background:$teal;
				}
				.change {
					background:$teal;
					font-weight:600;
				}
			}
			.dayTitle,
			.dayTimeLocation,
			.dayDuration,
			.dayType,
			.dayIntensity {
				text-overflow:ellipsis;
				overflow:hidden;
				// white-space: nowrap;
				border-bottom:1px solid rgba(0,0,0,0.10);
				padding:0.5em 0;
				color:rgba(0,0,0,0.60);
			}
			.dayIntensity {
				display:flex;
				align-items:center;
				justify-content: center;
				#icon {
					font-size:80%;
					margin:0 0.5em 0 0;
				}
			}
			.dayType {
				border:none;
			}
			.dayTitle {
				white-space:normal;
			}

			.dayBottomAction {
        position:absolute;
        width:100%;
        bottom:0;
        display: flex;
        flex-direction: row;
        justify-content:center;
        align-items:stretch;
        align-content:stretch;

				a, button {
					text-transform:uppercase;
					text-decoration:none;
					border-top:3px solid rgba(0,0,0,0.10);
					font-weight:700;
					padding:0.9em 0;
          background: rgba(0,0,0,0);
          color:$white;
          width:100%;

					#icon {
						font-size:75%;
						margin:0 0.3em 0 0;
						background:$blue;
					}
				}
                .dayButton {
                    width:50%;

				}
                .preview {
                    background:$blue;
                }
                .edit {
                    background:$teal;
                }
			}
		}


		// overrides

		.dayRest {
			background:transparent;
			.dayBottomAction {
				position:absolute;
				bottom:0;
			}
      .dayTheme {
        opacity:0.60;
      }
		}
		.dayCurrent {
			border-color:$yellow;
			border-width:3px;
			background:rgba(255,203,35,0.15);
			.dayTheme {
				background:$yellow;
        opacity:1;
			}
			.dayNumber { font-weight:500; }
			.dayName { font-weight:700; }
			.dayTheme { font-weight:700; }
		}
		.dayCompleted {
			border-color:$green;
			border-width:3px;
			background:rgba(77,228,145,0.15);
			.dayTheme {
				background:$green;
			}
		}

	}
}

//shawn TODO - find better places for this

.addTask {
  width:100%;
  position:absolute;
  bottom:0;
  border-top:2px solid rgba(0,0,0,0.10);
  height:2.9em;
}
.restDayAdd {
  background-color:transparent;
  position:absolute;
  bottom:0;
  margin-bottom: 10px;
  width: 26px;
  height: 26px;
  margin-left: -15px;
  margin-top:-3px !important;
}

.confettiBlast {
  position:absolute;
  top:0;
  left:0;
  bottom:0;
  right:0;
  width:auto;
  margin:0 auto;
  height:100%;
  display:block;
}

.timeButton {
    background: transparent;
    color:$teal;
}

// dupe of what's in print.css

@media print {

  body,
  .appUserDashboard {
    padding:0 !important;
    margin:0 !important;
  }



  .header {
    display:none !important;
  }






  .appProfileDetails {
    margin:30px 0 0 0;

  }
  .profilePic,
  .tableLink,
  .appUserDashboard .fatSepFull,
  .progressMeters,
  .appBackground,
  .appTableWorkoutHistory,
  .appTableMyPlans,
  .dayBottomAction,
  .printBtn
   {
    display:none !important;
  }
  .appUserDashboard .currentPlan {
    margin-top:1em !important;
    padding-top:0 !important;
    border-top:2px solid #ddd !important;
  }
  .appUserDashboard .detailsList {
    width:100% !important;
  }
  .appUserDashboard .detailsList ul {
    width:50% !important;
  }
  .day {
    padding:0 !important;
  }
  .dayCurrent {
    border-width:3px !important;
    border-color:#000 !important;
  }
  .dayImage {
    height:6em !important;
  }
  .dayTheme {
    color:#000 !important;
    opacity:1 !important;
    border-top:2px solid #ddd !important;
    border-bottom:2px solid #ddd !important;
  }
  /*
  .progressBar {
    box-shadow: inset 0 0 0 1000px #777;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#777777', endColorstr='#777777')";
  }
  .progressCount {
    padding-left:15px;
  }
  .progressWorkouts,
  .progressDays {
    box-shadow: inset 0 0 0 1000px #ddd;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#ddd', endColorstr='#ddd')";
  }
  */


  .printFix .appProgressSteps {
    display:none !important;
  }
  .appFullModal {

    overflow:auto !important;
    position:absolute !important;
    top:0 !important;
    bottom:auto !important;
    left:0 !important;
    right:auto !important;
    height:auto;
    box-shadow: inset 0 0 0 5000px #fff;
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr='#fff', endColorstr='#fff')";

  }
  .icon-play {
    display:none !important;
  }
  .changeDayModal .planPreviewModal .previewTop .previewLeft {
    width:100% !important;
    font-size:75% !important;
  }
  .previewRight {
    display:none !important;
  }
  .appFullModal .modalWrapper {
    height:auto !important;
    display:block;
    position:relative;
  }
  .modalHeader {
    display:none !important;
  }
  .appTableData .tableData tr td {
    padding:3px 0;
  }
  .previewTop #icon {
    font-size:130%;
  }
  .previewTop .publisher {
    width:2em;
  }

}

.planDebugger {
    background-color: orange;
    top: 100px;
    left:100px;
    position: absolute;
    z-index:2000;
    font-size: 12px;
    text-align: left;
}










// quick stab at media Q's








// BEGIN media queries
// copy pasta from Mock.scss
// matching Material-UI's grid breakpoints


// GOING up

// xs
@media only screen and (min-width: 0px) {

}

// sm
@media only screen and (min-width: 600px) {
    .planSteps {
        align-items:center;
    }
}

// md
@media only screen and (min-width: 900px) {

}

// lg
@media only screen and (min-width: 1280px) {

}

// xl
@media only screen and (min-width: 1920px) {

}



// GOING down

// xs
@media only screen and (max-width: 600px) {
  .appUsers {
    .or {
      padding:2em 0 0 0;
      margin:2em 0 0 0;

      p {
        border-left:none;
        border-top:1px solid rgba(0,0,0,0.20);
        width:100%;
        height:auto;

        span {
          left:auto;
          top:-1em;
          width:2em;
          text-align:center;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

// sm
@media only screen and (max-width: 900px) {


  .debugLinks { display:none !important; }


  // dashboard and table stuff

  .planCalendarWeek {
    .calendarContent {
      flex-direction: column;
      .day {
        width:100%;
        margin:1em 0;
        border-radius:10px;
        .dayImage {
          height:20vh;
        }
        .dayBottomAction {
          .preview {
            border-radius:0 0 0 7px;
          }
          .edit {
            border-radius:0 0 7px 0;
          }
        }
      }
      .dayRest {
        padding-bottom:3em;
      }
    }
  }
  .appProfileDetails {
    flex-direction: column;

    .installGO {
      display:none;
    }
    .profilePic {
      width:auto;
      padding:0;
    }
    .detailsList {
      width:100%;
      display:block;
      text-align:center;
      ul {
        margin:0 !important;
        width:100% !important;
        padding:0 !important;
        border:none !important;
      }
    }
  }
  .appOverflowContent {
    padding:0 1.5em;
    .fatSepFull {
      margin:0 0 0 -6em;
      padding:0 9em 0 0 0;
    }
  }
  .appUserDashboard {
    .currentPlan {
      h2 {
        width:47%;
        font-size:130%;
      }
      .planSummary {
        width:45%;
        padding:0 0 0 5%;
        .progressMeters {
          display:none;
        }
      }
    }
  }


  // progress stuff

  .appProgressSteps {
    padding:0 1.5em 0;
    height:100%;
    align-items: flex-start;
    .stepContent {
      padding:2em 0 4em 0;
    }
      .listItem{
        padding-right:5px;
      }
    .previousBtn, .nextBtn {
      transform:none;
      top:auto;
      bottom:0;
      left:0;
      right:auto;
      border-radius: 0;
      height:3.25em;
      padding:0;
      width:50%;
      background:$teal;
      #icon {
        fill:$white;
        height:1.1em;
        width:1.1em;
      }
    }
    .previousBtn {
      width:15%;
      background:$darkblue;
      // border-right:2px solid rgba(255,255,255,0.50);
    }
    .nextBtn {
      right:0;
      left:auto;
      width:85%;
      display:flex;
      flex-direction:row;
      .nextText {
        font-size:110%;
      }
    }
    .formDaysSelect {
      flex-direction: column;
      .daySelected {
        width:100%;
        padding:1em 0;
      }
      .day {
        max-width:none;
        width:100%;
        margin:0 0 1em;
        width:100%;
        padding:1em 0;
      }
    }
    .formDaysSelected {
      .day, .daySelected {
        width:90%;
        padding:1em 5%;
      }

    }
  }


  // preview modal

  .planPreviewModal {
    .appTableData {
      padding:0 1.5em;
    }
    .previewTop {
      .previewLeft {
        width:100%;
        h1 {
          font-size:160%;
          padding:1em 1.5em 0;
        }
        ul {
          padding-bottom:2em;
          li {
            padding:0 1em 0 0;
            .value {
              font-size:100%;
            }
            .label {
              font-size:80%;
            }
            .publisher {
              height:2em;
            }
            #icon {
              font-size:110%;
            }
          }
          li:last-child { display:none; }
        }
      }
      .previewRight {
        display:none;
      }
    }
    .previewTopExtra {
      padding:0 1.5em;
    }
  }

  // logged out stuff

  .appUsers {
    height: 100%;
    min-height: calc(100vh - 4.4em);
    padding:0 1.5em;

    h1 {
      padding:0;
      font-size:210%;
    }
    h2 {
      padding:0;
      font-size:100% !important;
    }

  }


  // modals
  .appModal {
      .modalWrapper {
        padding:1.5em;
        max-width:75%;
        .modalContent {
          .modalTitle {
            font-size:150%;
          }
          .modalSubTitle {

          }
          button {
            margin-bottom:0.2em;
          }

        }
      }
  }


  // cards
  .changeDayModal {
    .previewLeft {
      font-size: 90%;
      overflow-y: auto;
      padding: 1.5em 2.5% 0;
    }
    .resultsRight {

      .rightHeader {
        padding: 1em 0;
      }
      .replacementText {
        padding: 1em 1.25em;
        font-size: 90%;
      }
      .searchInput {
        position: relative;
        margin-top: -1em;
        top: auto;
        right: auto;
        padding: 0 1.5em;
        width: auto;
        align-items: flex-start;
      }
      .resultsCount {
        font-size: 100%;
        padding: 0 1em;
      }
      .resultsBox {
        .spacer {
          padding: 0;
        }
      }
      .workoutCard {
        font-size:90%;
        width:100% !important;
        margin:0 0 0.1em 0 !important;
        height:30vh !important;
        .workoutTitle {
          font-size:120% !important;
          line-height:1.2;
        }
        .workoutDetails {
          width:55%;
          flex-direction:column-reverse;
          .streamDetails {
            strong { display:none; }
          }
        }
        .ctas {
          position:absolute;
          bottom:0;
          top:auto;
          right:0em;
          width:5em;
          button {
            margin:0 0 0 0;
            text-align:center;
            width:100%;
            position:relative;
            padding:0.6em 0;
            top:auto;
            bottom:auto;
            right:auto;
            font-weight:600;
          }
          .workoutCardPreview {
            margin:0 0 0.2em 0;
            background:$blue;
            font-weight:300;
          }
        }
      }
    }
  }


  // welcome screen

  .appSplash {
    .versionNumber {
      opacity:0.40;
    }
    .bottom {
      text-align: center;
      flex-direction:column;
      align-items: center;
      justify-content: flex-end;
      height:100%;
      padding:2em 1.5em;
      width:auto;
      background:$darkblue-gradient-100;

      .logo {
        width:30%;
        margin:0;
      }
      .copy {
        padding:0;
        width:100%;
        h1 {
          font-size:210%;
        }
        h2 {
          font-size:110%;
          font-weight:500;
          padding:0.5em 5%;
        }
      }
      .cta {
        padding:1em 0 0.5em 0;
        width:100%;
        button {
          font-size:140%;
        }
      }
    }
  }



  // success

  .appCenterMessage {
    justify-content: flex-start;
    h1 {
      font-size:200%;
    }
    h2 {
      font-size:100%;
    }
    .planFeatures {
      flex-direction: column;
      border:none;
      width:100%;
      padding:0;
      // flex-flow:wrap;

      #icon {
        margin:0 0.5em 0 0;
        font-size:100%;
      }

      .planFeaturesTitle {
        display:none;
      }
      p {
        border-bottom:1px solid rgba(0,0,0,0.10);
        display:flex;
        align-items: center;
        justify-content: flex-start;
        padding:0 0 0.5em 0;
        margin:0 0 0.5em 0;
      }
      p:last-child {
        border:none;
        margin:0;
        padding:0;
      }
    }
  }



  .button-teal,
  .button-blue {
    font-size:120%;
    padding:0.6em 1.2em;
  }



  // background icon-24GO

  .appBackground {
    #icon.icon-gologo {
      width:55vh;
      right:-18%;
    }
    .vidIntro {
      display:none;
    }
  }

  .appTableData {
    font-size:85%;
    .icon-play {
      display:none !important;
    }

    .tableData {
      tr {

        th {

        }
      }
      td {
        /*
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width:12em;
        */
      }
    }
  }
  .appTableData.plans, .appTableData.workoutHistory {
    display: block;
    thead, tbody, th, td, tr {
      display: block;
    }

    .tbody tr {
      border-bottom: 4px solid rgba(0, 0, 0, 0.1);
    }
    td {
      border: none;
      border-bottom: 1px solid #ccc !important;
      position: relative;
      padding-left: 50% !important;
      text-align:left
    }
    td:before {
      position: absolute;
      left: 6px;
      width: 45%;
      padding-right: 10px;
      white-space: nowrap;
      font-size: 110%;
      font-weight: 400;
    }
  }

  .appTableData.plans {
    td:nth-of-type(1):before { content: "Plan Name"; }
    td:nth-of-type(2):before { content: "Started"; }
    td:nth-of-type(3):before { content: "Ended"; }
    td:nth-of-type(4):before { content: "Workouts"; }
    td:nth-of-type(5):before { content: "Completed"; }
  }
  .appTableData.workoutHistory {
    td:nth-of-type(1):before { content: "Date"; }
    td:nth-of-type(2):before { content: "Start Time"; }
    td:nth-of-type(3):before { content: "Duration"; }
    td:nth-of-type(4):before { content: "Name"; }
    td:nth-of-type(5):before { content: "Type"; }
  }



  .printBtn { display:none !important; }



}

// md
@media only screen and (max-width: 1280px) {

}

// lg
@media only screen and (max-width: 1920px) {

}
