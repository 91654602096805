.debugLinks{
    left:0;
    bottom:0;

    position: fixed;
    overflow: scroll;
    background:#fff;
    z-index:1000;
    padding:0.5em 1em;
    box-shadow:0 0 30px rgba(0,0,0,0.10);

    button {
      text-transform:uppercase;
      background:gray;
      color:white;
      margin:0.3em;
      padding:0.1em 0.3em;
    }
}
.debugText {
    color: red;
    width:auto;

}
