.group-class {
  padding-top:1em;

  .group-item {
    align-items: center;
    display:flex;
    justify-content: center;
    position: relative;
  }

  .group-item-card {
    border:1px solid rgba(0,0,0,0.15);
    border-radius:6px;
    height:100%;
    margin:2px;
    padding:0 1em;
    position:relative;

    .card-checkbox {
      color: '#3CB6CE';
      position: absolute !important;
      right: 0;
      top: 0;
    }

    h5 {
      font-size:120%;
      border-bottom:1px solid rgba(0,0,0,0.15);
      padding:0 0 0.5em 0;
      margin:0 0 0.6em 0;
      font-weight:400;
      width: 100%;
      text-align: center;
    }

    .icon {
      width:3.4em;
      height:3.4em;
      opacity:0.60;
      padding:0.7em 0 0 0;
    }

    p {
      width: 100%;
      font-size: 90%;
      line-height: 1.4;
      opacity: 0.60;
      padding: 0 0 0.75em 0;
      text-align: center;
    }

    input {
      position:absolute;
      top:0;
      right:0;
      margin:1em;
    }
  }

  .group-item-card-selected {
    border:3px solid rgba(0,0,0,0.40);
    transition:0.5s all ease-out;
    -ms-transition: none;
    margin:0;

    h5 {
      font-weight:bold;
      transition:0.5s font-weight ease-out;
      -ms-transition: none;
    }

    p {
      opacity:1;
    }
    
    .icon {
      opacity:1;
      transition:0.5s opacity ease-out;
      -ms-transition: none;
    }
  }
}
